<script setup lang="ts">
import { AdminApi } from '@eggor/vue-shared'

definePage({
  name: 'ProfileEdit',
})

const infoResult = AdminApi.useInfo({ immediate: false })
onActivated(infoResult.execute)
useOnUserChanged(() => infoResult.execute())
</script>

<template>
  <e-remote :result="infoResult">
    <template #default="{ data, execute }">
      <profile-edit :user="data.value!.user" @saved="execute" />
    </template>
  </e-remote>
</template>

<style scoped>

</style>
