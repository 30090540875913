<script setup lang="ts">
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'

definePage({
  name: 'ProfileAgent',
})
const userStore = useUserStore()
const { t } = useI18n()
const result = WebsiteApi.useUserInfo(userStore.user!.brokerInfo?.userId, { immediate: false })
if (userStore.user!.brokerInfo) {
  result.execute()
}
const currentTab = ref<'first' | 'second'>('first')

const profileItem = ref({
  all: false,
  avatar: false,
  nickname: false,
  intro: false,
  bio: false,
})
let reactiveSet = false
watch([() => profileItem.value.all, profileItem], ([newAll], [oldAll]) => {
  if (newAll !== oldAll) {
    if (reactiveSet) {
      return reactiveSet = !reactiveSet
    }
    for (const key in profileItem.value) {
      profileItem.value[key as 'avatar'] = newAll
    }
  }
  else {
    for (const key in profileItem.value) {
      if (profileItem.value[key as 'avatar'] === true) {
        if ((key as string) === 'all') {
          continue
        }
        if (newAll === false) {
          profileItem.value.all = true
          reactiveSet = true
        }
        return
      }
    }
    if (newAll === true) {
      profileItem.value.all = false
    }
  }
}, { deep: true })

const { execute, data } = WebsiteApi.useCancelAgency(userStore.user!.brokerInfo.applicationId, { immediate: false })

const message = useMessage()
async function handleCancellation() {
  execute()
}
watch(data, () => {
  if (data.value && data.value.code !== 500) {
    message.success('Succeed to cancel the agency')
  }
  else {
    message.error('Operation failed, please try again')
  }
})
</script>

<template>
  <n-tabs v-model:value="currentTab" animated :tab-style="{ display: 'none' }">
    <n-tab-pane name="first">
      <e-remote :result="result">
        <template #empty>
          <div class="pt-20">
            <n-empty>
              {{ t('profile.agent.noAgent') }}
            </n-empty>
          </div>
        </template>
        <template #default="{ data }">
          <div class="w-max-62.5rem h-20.6875rem relative">
            <img loading="lazy" class="w-full h-full" src="/images/temp_profile_bg.png">
            <n-image class="rounded-full w-9.375rem h-9.375rem absolute top-4.375rem left-3.125rem" object-fit="cover" :src="data.value?.data?.avatar" />
            <div class="absolute top-5 right-5 text-gray-300 flex gap-5">
              <span>{{ t('profile.agent.expirationTime') }}</span> <n-time class="text-gray-100" :time="new Date(userStore.user!.brokerInfo.effectiveTime)" />
            </div>
            <router-link v-if="data.value?.data" :to="{ name: 'AgentInfo', params: { id: (data.value?.data?.userId) as string } }">
              <div class="absolute top-4.375rem left-13.75rem  text-1rem text-white lh-1.1719rem col gap-1.25rem vertical-text-top mr-1.25rem">
                <div class="font-bold text-1.25rem! lh-1.465rem ">
                  <span>{{ data.value?.data?.nickName }}</span>
                  <!-- <svg-icon v-if="data.value?.data?.dept.deptId !== 204" class="ml-0.625rem w-1.125rem h-1.125rem -translate-y-0.15rem" :name="svgName" color="none" /> -->
                </div>
                <p>
                  {{ data.value?.data?.userName }}
                </p>
                <p class="line-clamp-2">
                  {{ data.value?.data?.biography }}
                </p>
                <p class="line-clamp-2">
                  {{ data.value?.data?.introduction }}
                </p>
              </div>
            </router-link>
          </div>

          <div class="mt-3.125rem text-0.875rem text-right">
            <n-button class="w-8.4375rem h-2.5rem mr-1.25rem" @click="handleCancellation">
              {{ t("profile.agent.Cancellation") }}
            </n-button>
            <n-button class="w-8.4375rem h-2.5rem mr-1.25rem" @click="currentTab = 'second'">
              {{ t('profile.agent.Settings') }}
            </n-button>
            <router-link v-if="data.value?.data" :to="{ name: 'AgentInfo', params: { id: (data.value?.data?.userId) as string } }">
              <n-button class="w-8.4375rem h-2.5rem mr-1.25rem">
                {{ t('profile.agent.profile') }}
              </n-button>
            </router-link>
          </div>
        </template>
      </e-remote>
    </n-tab-pane>
    <n-tab-pane name="second">
      <n-form label-placement="left">
        <div>
          <n-form-item label="个人资料管理">
            <n-switch v-model:value="profileItem.all" />
          </n-form-item>
          <div class="grid grid-cols-2">
            <n-form-item label="头像">
              <n-switch v-model:value="profileItem.avatar" />
            </n-form-item>
            <n-form-item label="昵称">
              <n-switch v-model:value="profileItem.nickname" />
            </n-form-item>
            <n-form-item label="个人简介">
              <n-switch v-model:value="profileItem.intro" />
            </n-form-item>
            <n-form-item label="BIO">
              <n-switch v-model:value="profileItem.bio" />
            </n-form-item>
          </div>
        </div>
        <div>
          <n-form-item label="社区管理">
            <n-switch />
          </n-form-item>
          <div class="grid grid-cols-2">
            <n-form-item label="基本信息">
              <n-switch />
            </n-form-item>
            <n-form-item label="权限">
              <n-switch />
            </n-form-item>
            <n-form-item label="粉丝">
              <n-switch />
            </n-form-item>
            <n-form-item label="申请">
              <n-switch />
            </n-form-item>
            <n-form-item label="帖子">
              <n-switch />
            </n-form-item>
          </div>
        </div>
        <div>
          <n-form-item label="活动管理">
            <n-switch />
          </n-form-item>
          <div class="grid grid-cols-2">
            <n-form-item label="创建">
              <n-switch />
            </n-form-item>
            <n-form-item label="修改">
              <n-switch />
            </n-form-item>
            <n-form-item label="参加活动">
              <n-switch />
            </n-form-item>
          </div>
        </div>
        <div>
          <n-form-item label="专辑管理">
            <n-switch />
          </n-form-item>
          <div class="grid grid-cols-2">
            <n-form-item label="专辑创建">
              <n-switch />
            </n-form-item>
            <n-form-item label="上传 NFT">
              <n-switch />
            </n-form-item>
            <n-form-item label="挂单">
              <n-switch />
            </n-form-item>
            <n-form-item label="取消挂单">
              <n-switch />
            </n-form-item>
            <n-form-item label="接受报价">
              <n-switch />
            </n-form-item>
            <n-form-item label="编辑挂单">
              <n-switch />
            </n-form-item>
            <n-form-item label="转赠 NFT">
              <n-switch />
            </n-form-item>
            <n-form-item label="拍卖管理">
              <n-switch />
            </n-form-item>
          </div>
        </div>
      </n-form>
      <n-button class="w-8.4375rem h-2.5rem mr-1.25rem" @click="currentTab = 'first'">
        {{ t('return') }}
      </n-button>
      <n-button class="w-8.4375rem h-2.5rem mr-1.25rem" @click="currentTab = 'first'">
        {{ t('save') }}
      </n-button>
    </n-tab-pane>
  </n-tabs>
</template>
