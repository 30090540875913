<script setup lang="ts">
definePage({
  name: 'Search',
})
const router = useRouter()
const route = useRoute()
const query = ref(Array.isArray(route.query.q)
  ? route.query.q.length > 0
    ? route.query.q[0] as string
    : ''
  : route.query.q as string ?? '')
const querying = ref<string>(query.value)
const tab = ref(route.query.t as string || 'user')

watch([querying, tab], ([querying, tab]) => {
  router.push({ name: 'Search', query: { q: querying, t: tab } })
})
const { t } = useI18n()
</script>

<template>
  <layouts-wrapper>
    <div class="max-w-24rem m-auto pt-2.5rem">
      <n-input
        v-model:value="query"
        size="large"
        round
        :placeholder="t('search.hint')"
        @keydown.enter="querying = query"
      >
        <template #suffix>
          <n-icon class="cursor-pointer" @click="querying = query">
            <span class="i-carbon-search" />
          </n-icon>
        </template>
      </n-input>
    </div>

    <n-tabs v-if="querying" v-model:value="tab" class="mt-2rem" type="line" animated>
      <!-- <n-tab-pane name="item" tab="Item">
        Wonderwall
      </n-tab-pane>
      <n-tab-pane name="collection" tab="Collection">
        Hey Jude
      </n-tab-pane> -->
      <n-tab-pane name="user" tab="User">
        <search-users :query="querying" />
      </n-tab-pane>
      <n-tab-pane name="post" tab="Post">
        <search-posts :query="querying" />
      </n-tab-pane>
      <n-tab-pane name="community" tab="Community">
        <search-communities :query="querying" />
      </n-tab-pane>
    </n-tabs>
  </layouts-wrapper>
</template>

<style scoped>

</style>
