<script setup lang="ts">
import type { Connector } from 'use-wagmi'
import { useAccount, useConnect, useDisconnect } from 'use-wagmi'
import { useUserStore } from '@eggor/vue-shared'

const { t } = useI18n()
const message = useMessage()
const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
const { connector, address } = useAccount()
const { disconnect, error: disconnectError } = useDisconnect()
const userStore = useUserStore()
// const { logout } = useWebsiteAppStore()

watch([error, disconnectError], (errors: any[]) => {
  errors.forEach((error) => {
    if (!error)
      return
    if (error.code === 4001)
      message.error(t('core.signInModal.rejected'))
    else if (error.name === 'ConnectorNotFoundError')
      message.error(t('core.signInModal.metamaskRequired'))
    else message.error(error.message ?? error.toString())
  })
})

const filteredConnectors = computed(() => {
  return connectors.value.reduce((pre, cur, _index, _arr) => {
    if (!pre.find(_ => _.name === cur.name))
      return [...pre, cur]
    return pre
  }, [] as Connector[])
})

function handleConnect(connector: Connector) {
  disconnect()
  connect({ connector })
}
function handleLogout() {
  // logout()
  // disconnect()

  window.localStorage.clear()
  location.assign('/')
}
</script>

<template>
  <h3 class="text-gray">
    {{ t("core.signInModal.selectConnector") }}
  </h3>
  <n-list>
    <n-list-item v-for="connectorItem, index in filteredConnectors" :key="index">
      <n-thing :title="connectorItem.name" :description="connector?.id === connectorItem.id ? address : ''" :description-style="{ fontSize: '0.5rem', opacity: 0.7 }" />

      <template #suffix>
        <n-button v-if="!connector" :disabled="isLoading" :loading="pendingConnector?.id === connectorItem.id && isLoading" type="primary" @click="handleConnect(connectorItem)">
          {{ t('core.signInModal.connect') }}
        </n-button>
        <n-button v-else-if="!!connector && connector.id === connectorItem.id" disabled>
          {{ t('core.signInModal.connected') }}
        </n-button>
        <n-button v-else-if="!!connector && connector.id !== connectorItem.id" type="primary" @click="handleConnect(connectorItem)">
          {{ t('core.signInModal.switch') }}
        </n-button>
      </template>
    </n-list-item>
  </n-list>

  <n-button v-if="userStore.id" class="w-full mt-2rem" type="error" @click="handleLogout">
    {{ t('core.signInModal.logout') }}
  </n-button>
</template>

<style scoped>

</style>
