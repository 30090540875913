<script setup lang="ts">
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'
import { ArtistDeptId, CollectorDeptId } from '~/constants'

definePage({
  name: 'ProfileActivityManage',
})
const { t } = useI18n()
const userStore = useUserStore()
const activityType = ref<number>()
const { data: ongoing } = [ArtistDeptId, CollectorDeptId].includes(userStore.user?.dept.deptId as number) ? WebsiteApi.useMyActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 1 }))) : WebsiteApi.useUserActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 1 })))
const { data: coming } = [ArtistDeptId, CollectorDeptId].includes(userStore.user?.dept.deptId as number) ? WebsiteApi.useMyActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 2 }))) : WebsiteApi.useUserActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 2 })))
const { data: history } = [ArtistDeptId, CollectorDeptId].includes(userStore.user?.dept.deptId as number) ? WebsiteApi.useMyActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 3 }))) : WebsiteApi.useUserActivities(userStore.user?.userId ?? '', { pageSize: 6, pageNum: 1 }, { refetch: true }, computed(() => ({ type: activityType.value ?? '', status: 3 })))
</script>

<template>
  <div class="mb-1.25rem row justify-between">
    <p class="text-1rem">
      {{ t('profile.activityManage.title') }}
    </p>
    <n-select
      v-model:value="activityType" :options="[
        {
          label: t('profile.activityManage.create'),
          value: 1,
        },
        {
          label: t('profile.activityManage.join'),
          value: 2,
        },
      ]" style="width: 7.5rem;"
    />
  </div>
  <hr>
  <div v-if="[ArtistDeptId, CollectorDeptId].includes(Number(userStore.user?.dept?.deptId))" class="mb-3.125rem mt-1.25rem">
    <router-link :to="{ name: 'EventCreation' }">
      <div class="w-25.6875rem h-10.25rem border-dashed border-black border-0.125rem rounded-1.25rem text-center">
        <div class="pt-2.9375rem mb-0.625rem">
          <span class="i-icon-park-outline-add-one text-2.5rem" />
        </div>
        <p>{{ t('profile.activityManage.createActivity') }}</p>
      </div>
    </router-link>
  </div>
  <profile-event-card :title="t('profile.activity.card.ongoing')" :activity="ongoing?.rows ?? []" />
  <profile-event-card :title="t('profile.activity.card.coming')" :activity="coming?.rows ?? []" />
  <profile-event-card :title="t('profile.activity.card.history')" :activity="history?.rows ?? []" />
</template>

<style scoped>

</style>
