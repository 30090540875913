<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import { I18nT } from 'vue-i18n'

const route = useRoute()
const invitation = computed(() => route.query.invitation as string | undefined)
const userInfo = WebsiteApi.useUserInfo(atob(invitation.value ?? ''), {
  immediate: !!invitation.value,
  refetch() {
    return !!invitation.value
  },
})
</script>

<template>
  <e-remote v-if="invitation" :result="userInfo">
    <template #default="{ data }">
      <template v-if="data.value?.data">
        <div class="inline-row gap-1rem text-1rem">
          <n-avatar :src="data.value!.data!.avatar" class="shrink-0" />
          <I18nT keypath="profile.invitation.invitesYou" tag="span">
            <template #name>
              <span class="font-bold">
                {{ data.value.data.nickName }}
              </span>
            </template>
          </I18nT>
        </div>
      </template>
    </template>
  </e-remote>
</template>

<style scoped>

</style>
