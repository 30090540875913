<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'Post',
})
const route = useRoute<'Post'>()
// 带参页面不要响应式！
const id = route.params.id
const postResult = WebsiteApi.usePostById(id)
useAdaptiveRefetch(postResult)
</script>

<template>
  <layouts-wrapper>
    <e-remote :result="postResult">
      <template #default="{ data }">
        <community-post
          :post="data.value!.data!"
          class="pt-2.5rem m-auto max-w-58.125rem"
        />
      </template>
    </e-remote>
  </layouts-wrapper>
</template>

<style scoped>

</style>
