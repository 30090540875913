<script setup lang="ts">
definePage({
  name: 'ProfileCommunityManage',
})
</script>

<template>
  <profile-community-management />
</template>

<style scoped>

</style>
