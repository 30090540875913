<script setup lang="ts">
const { t } = useI18n()

definePage({
  name: 'FollowFans',
})
const list = reactive([{
  id: '0',
  nickname: '用户名用户名',
  avatar: '/images/temp_avatar.png',
}, {
  id: '1',
  nickname: '用户名用户名',
  avatar: '/images/temp_avatar.png',
}, {
  id: '2',
  nickname: '用户名用户名',
  avatar: '/images/temp_avatar.png',
}])
</script>

<template>
  <div>
    <div
      v-for="item in list"
      :key="item.id"
      class="mb-0.94rem py-3.12rem px-4.38rem shadow-sm bg-[rgba(255,255,255,0.85)] rounded row items-center gap-1.25rem"
    >
      <n-avatar :src="item.avatar" class="w-4.875rem h-4.875rem shrink-0" circle />
      <div class="row justify-between w-100%">
        <h3 class="font-bold flex items-center">
          {{ item.nickname }}
        </h3>
        <p class="">
          <n-button>{{ t('follow.unfollow') }}</n-button>
        </p>
      </div>
    </div>

    <e-pagination :show-size-picker="false" :page-size="5" class="justify-center" />
  </div>
</template>

<style scoped>

</style>
