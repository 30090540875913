import { defineStore } from 'pinia'
import type { UserModel } from '@eggor/vue-shared'
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'
import { useAccount } from 'use-wagmi'

export default defineStore('websiteApp', () => {
  const showWalletConnector = ref(false)
  const accountBook = useLocalStorage<{ address: string; token: string; info: UserModel; permissions: string[] }[]>('account_book', [])
  const userStore = useUserStore()

  const noticeUnreadCount = useNoticeUnreadCount()

  const { address } = useAccount()

  watch(address, (address, oldOne) => {
    if ((oldOne?.length ?? 0) > 1 && userStore.token && userStore.user) {
      const cache = accountBook.value.find(_ => _.address === oldOne)
      if (!cache)
        accountBook.value.push({
          address: oldOne!,
          token: userStore.token,
          info: userStore.user,
          permissions: userStore.permissions,
        })
      userStore.$patch({
        user: null,
        token: null,
        permissions: [],
      })
    }
    if ((address?.length ?? 0) > 1) {
      const cache = accountBook.value.find(_ => _.address === address)
      if (!cache) {
        showWalletConnector.value = true
        return
      }
      userStore.setToken(cache.token)
      userStore.setInfo(cache.info, cache.permissions)
    }
  })

  return {
    showWalletConnector,
    noticeUnreadCount,
    /**
     * 完全退出登录
     */
    logout() {
      accountBook.value.splice(accountBook.value.findIndex(_ => _.address === address.value), 1)
      userStore.logout()
    },
  }
})

/**
 * @deprecated use onUserChanged
 * @param fn
 */
export function useOnUserChanged(fn: (user: UserModel | null) => void) {
  const store = storeToRefs(useUserStore())
  watch(store.token, _ => fn(store.user.value))
}

export function onUserChanged(fn: (user: UserModel | null) => void) {
  const store = storeToRefs(useUserStore())
  watch(store.token, _ => fn(store.user.value))
}

function useNoticeUnreadCount() {
  const { data, abort, isFetching, execute } = WebsiteApi.useNotificationUnreadCount({ immediate: false })
  const store = useUserStore()
  onUserChanged(() => {
    abort()
    if (store.token) {
      execute()
    }
  })
  onMounted(() => {
    if (!isFetching.value && !!store.id)
      execute()
  })
  watchPostEffect((onCleanup) => {
    if (!data.value || !store.id)
      return
    const _ = setInterval(() => execute(false), 5000)
    onCleanup(() => clearInterval(_))
  })

  const total = computed(() => {
    if (!data.value || !data.value.data)
      return 0
    return data.value.data!.comment + data.value.data!.like + data.value.data!.system + data.value.data!.at
  })
  return {
    total,
    comment: computed(() => data.value?.data?.comment ?? 0),
    system: computed(() => data.value?.data?.system ?? 0),
    like: computed(() => data.value?.data?.like ?? 0),
    news: computed(() => (data.value?.data as { news?: number })?.news ?? 0),
    at: computed(() => (data.value?.data)?.at ?? 0),
  }
}
