<script setup lang="ts">
import { WebsiteApi, useDictByType, useUserStore } from '@eggor/vue-shared'

definePage({
  name: 'ProfileAuthentication',
})
const userStore = useUserStore()
const { t } = useI18n()
const messager = useMessage()
const identityResult = WebsiteApi.useMyUserIdentity({ immediate: false })
const agentIdentityResult = WebsiteApi.useAgentInfo(computed(() => userStore.user ? userStore.user.userId : 0), { immediate: false })
const identitySubmitMutation = WebsiteApi.useSubmitIdentityMutation(computed(() => identityResult.data.value?.data?.id))

const statusDict = useDictByType('sys_identity_status', computed(() => identityResult.data.value?.data?.status?.toString()))
const typeDict = useDictByType('sys_role_identity', computed(() => identityResult.data.value?.data?.applyIdentity?.toString()))
// console.log(agentIdentityResult.data)
onActivated(identityResult.execute)
onActivated(() => {
  if (userStore.user) {
    agentIdentityResult.execute()
  }
})
onUserChanged(() => identityResult.execute(false))

watch(identitySubmitMutation.data, (data) => {
  if (data) {
    messager.success(t('legalize.applicationSubmitted'))
    identityResult.execute(false)
  }
})
const isArtist = computed(() => {
  return identityResult.data.value?.data?.applyIdentity?.toString() === '1'
})
</script>

<template>
  <e-remote-multi :result="[identityResult, agentIdentityResult]">
    <template #empty>
      <div class="col gap-1.25rem">
        <p class="lh-1.25rem text-1rem">
          {{ t('profile.authentication.title') }}
        </p>
        <hr>

        <div class="row gap-1.25rem">
          <router-link :to="{ name: 'LegalizeArtist' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.artist') }}
            </n-button>
          </router-link>
          <router-link :to="{ name: 'LegalizeCollector' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.collector') }}
            </n-button>
          </router-link>
          <!-- <router-link :to="{ name: 'LegalizeAgent' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.agent') }}
            </n-button>
          </router-link> -->
        </div>
      </div>
    </template>
    <template #default="{ result: mergedResult }">
      <div v-if="Array.isArray(mergedResult) && mergedResult.length === 2" class="col gap-1.25rem">
        <p class="lh-1.25rem text-1rem">
          {{ t('profile.authentication.title') }}
        </p>
        <hr>

        <div v-if="!mergedResult[0].data.value?.data && !mergedResult[1].data.value?.data" class="row gap-1.25rem">
          <router-link :to="{ name: 'LegalizeArtist' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.artist') }}
            </n-button>
          </router-link>
          <router-link :to="{ name: 'LegalizeCollector' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.collector') }}
            </n-button>
          </router-link>
          <router-link :to="{ name: 'LegalizeAgent' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.agent') }}
            </n-button>
          </router-link>
        </div>

        <div v-else>
          <div v-if="mergedResult[0].data.value?.data">
            <n-descriptions label-placement="left">
              <n-descriptions-item :label="t('profile.authentication.type')">
                {{ typeDict?.dictLabel }}
              </n-descriptions-item>
              <n-descriptions-item :label="t('profile.authentication.status.title')">
                <n-text :type="statusDict?.listClass">
                  {{ statusDict?.dictLabel }}
                </n-text>
              </n-descriptions-item>
              <n-descriptions-item v-if="mergedResult[0].data.value!.data!.status !== 3" :label="t('profile.authentication.reason')">
                {{ mergedResult[0].data.value!.data!.reason || '-' }}
              </n-descriptions-item>
            </n-descriptions>

            <n-space class="mt-1rem">
              <router-link :to="{ name: isArtist ? 'LegalizeArtist' : 'LegalizeCollector' }">
                <n-button
                  v-if="[1, 3].includes(mergedResult[0].data.value!.data!.status)"
                  type="primary"
                >
                  {{ t('profile.authentication.view') }}
                </n-button>
              </router-link>
              <router-link :to="{ name: isArtist ? 'LegalizeArtist' : 'LegalizeCollector' }">
                <n-button
                  v-if="[0, 2].includes(mergedResult[0].data.value!.data!.status)"
                  type="primary"
                >
                  {{ t('profile.authentication.viewOrEdit') }}
                </n-button>
              </router-link>
              <n-button
                v-if="[0].includes(mergedResult[0].data.value!.data!.status)"
                :loading="identitySubmitMutation.isFetching.value"
                @click="() => identitySubmitMutation.execute(false)"
              >
                {{ t('submit') }}
              </n-button>
            </n-space>
          </div>
          <div v-else>
            <n-descriptions label-placement="left">
              <n-descriptions-item :label="t('profile.authentication.type')">
                {{ t('roles.agent') }}
              </n-descriptions-item>
              <n-descriptions-item :label="t('profile.authentication.status.title')">
                <!-- <n-text :type="statusDict?.listClass">
                  Under review
                </n-text> -->
                <span v-if="mergedResult[1].data.value!.data!.status === 1">{{ t('profile.authentication.status.underReview') }}</span>
                <span v-if="mergedResult[1].data.value!.data!.status === 2">{{ t('profile.authentication.status.rejected') }}</span>
                <span v-if="mergedResult[1].data.value!.data!.status === 3">{{ t('profile.authentication.status.passed') }}</span>
              </n-descriptions-item>
              <n-descriptions-item v-if="mergedResult[1].data.value!.data!.status !== 3" :label="t('profile.authentication.reason')">
                {{ mergedResult[1].data.value!.data!.reason || '-' }}
              </n-descriptions-item>
            </n-descriptions>

            <n-space class="mt-1rem">
              <router-link :to="{ name: 'LegalizeAgent' }">
                <n-button
                  v-if="[1, 3].includes(mergedResult[1].data.value!.data!.status)"
                  type="primary"
                >
                  {{ t('profile.authentication.view') }}
                </n-button>
              </router-link>
              <router-link :to="{ name: 'LegalizeAgent' }">
                <n-button
                  v-if="[0, 2].includes(mergedResult[1].data.value!.data!.status)"
                  type="primary"
                >
                  {{ t('profile.authentication.viewOrEdit') }}
                </n-button>
              </router-link>
              <!-- <n-button
                v-if="[0].includes(agentData.value!.data!.status)"
                :loading="identitySubmitMutation.isFetching.value"
                @click="() => identitySubmitMutation.execute(false)"
              >
                {{ t('submit') }}
              </n-button> -->
            </n-space>
          </div>
        </div>

        <hr>

        <p class="lh-1.25rem text-1rem">
          {{ t('profile.authentication.bindEmail.title') }}
        </p>

        <profile-authentication-bind-email class="max-w-30rem" />
      </div>
    </template>
  </e-remote-multi>
  <!-- <e-remote :result="identityResult">
    <template #default="{ data }">
      <div class="col gap-1.25rem">
        <p class="lh-1.25rem text-1rem">
          {{ t('profile.authentication.title') }}
        </p>
        <hr>

        <div v-if="!data.value?.data" class="row gap-1.25rem">
          <router-link :to="{ name: 'LegalizeArtist' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.artist') }}
            </n-button>
          </router-link>
          <router-link :to="{ name: 'LegalizeCollector' }">
            <n-button>
              {{ t('profile.authentication.applyToBe.collector') }}
            </n-button>
          </router-link>
          <router-link :to="{ name: 'LegalizeAgent' }">
            <n-button>
              Apply to be an Agent
            </n-button>
          </router-link>
        </div>

        <div v-else>
          <n-descriptions label-placement="left">
            <n-descriptions-item :label="t('profile.authentication.type')">
              {{ typeDict?.dictLabel }}
            </n-descriptions-item>
            <n-descriptions-item :label="t('profile.authentication.status.title')">
              <n-text :type="statusDict?.listClass">
                {{ statusDict?.dictLabel }}
              </n-text>
            </n-descriptions-item>
            <n-descriptions-item v-if="data.value.data.status !== 3" :label="t('profile.authentication.reason')">
              {{ data.value.data.reason || '-' }}
            </n-descriptions-item>
          </n-descriptions>

          <n-space class="mt-1rem">
            <router-link :to="{ name: 'LegalizeArtist' }">
              <n-button
                v-if="[1, 3].includes(data.value.data.status)"
                type="primary"
              >
                {{ t('profile.authentication.view') }}
              </n-button>
            </router-link>
            <router-link :to="{ name: 'LegalizeArtist' }">
              <n-button
                v-if="[0, 2].includes(data.value.data.status)"
                type="primary"
              >
                {{ t('profile.authentication.viewOrEdit') }}
              </n-button>
            </router-link>
            <n-button
              v-if="[0].includes(data.value.data.status)"
              :loading="identitySubmitMutation.isFetching.value"
              @click="() => identitySubmitMutation.execute(false)"
            >
              {{ t('submit') }}
            </n-button>
          </n-space>
        </div>

        <hr>

        <p class="lh-1.25rem text-1rem">
          {{ t('profile.authentication.bindEmail.title') }}
        </p>

        <profile-authentication-bind-email class="max-w-30rem" />
      </div>
    </template>
  </e-remote> -->
</template>

<style scoped>

</style>
