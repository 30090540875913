<script setup lang="ts">
import type { FileInfo } from 'naive-ui/es/upload/src/interface'

definePage({
  name: 'CollectionNFTCreation',
})

const { t } = useI18n()
const fileList = reactive<FileInfo[]>([])
</script>

<template>
  <layouts-wrapper class="pt-2.5rem">
    <n-text class="text-2xl font-bold">
      {{ t('collection.nft.creation.title') }}
    </n-text>

    <n-form class="w-full pt-2rem col gap-1rem items-stretch">
      <n-form-item :label="t('collection.nft.creation.workFile')">
        <div>
          <n-p depth="3" class="text-xs">
            {{ t('collection.nft.creation.uploadFileDesc') }}
          </n-p>
          <n-upload
            action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
            :default-file-list="fileList"
            list-type="image-card"
            :max="1"
          >
            {{ t('collection.nft.creation.uploadFile') }}
          </n-upload>
        </div>
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.name')">
        <n-input class="max-w-20rem" />
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.externalLink')">
        <div class="w-full">
          <n-p depth="3" class="text-xs">
            {{
              t('collection.nft.creation.externalLinkDesc')
            }}
          </n-p>
          <n-input class="max-w-50rem" />
        </div>
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.details')">
        <n-input type="textarea" autosize class="min-h-10rem max-w-50rem" />
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.album')">
        <n-select class="max-w-20rem" />
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.chain')">
        <n-select class="max-w-20rem" />
      </n-form-item>

      <n-form-item :label="t('collection.nft.creation.quantity')">
        <n-input-number class="max-w-20rem w-full" />
      </n-form-item>

      <n-button round type="primary" class="max-w-10rem">
        {{ t('collection.nft.creation.create') }}
      </n-button>
    </n-form>
  </layouts-wrapper>
</template>

<style scoped>

</style>
