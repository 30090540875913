import type { MaybeRefOrGetter } from 'vue'

export default function (rem: MaybeRefOrGetter<string>) {
  const px = getCurrentScope()?.run(() => {
    const px = ref(0)
    watchEffect(() => {
      px.value = remToPx(toValue(rem))
    })
    const { width } = useWindowSize()
    watch(width, () => {
      px.value = remToPx(toValue(rem))
    })
    return px
  }) ?? computed(() => remToPx(toValue(rem)))
  return toRef(px)
}
export function remToPx(rem: string) {
  return Number.parseFloat(toValue(rem)) * Number.parseFloat(getComputedStyle(document.documentElement).fontSize)
}
