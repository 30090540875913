<script setup lang="ts">
const props = defineProps({
  ghost: { type: Boolean }
})

const ghost = computed(() => !!props.ghost)
const { t } = useI18n()
</script>

<template>
  <div class="search-bar  row items-center px-0.63rem flex-gap-0.63rem" :class="{ ghost }">
    <svg-icon name="search" color="none" />
    <span class="hint">{{ t("header.searchBar.hint") }}</span>
  </div>
</template>

<style scoped lang="scss">
.search-bar {
  height: 2.1875rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: #EDEDED;
  &.ghost {
    background: rgba($color: #EDEDED, $alpha: 0.1);
  }
}
.search-icon {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
}
.hint {
  color: #BCB9B4;
  font-family: PingFang SC;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
