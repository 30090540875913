<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="col">
    <layouts-default-header-eggor-logo mode="black" class="!w-8.75rem !h-2.125rem" />

    <div class="mt-1.875rem w-full col md:row gap-1rem md:gap-2rem">
      <div class="grow">
        <p class="w-31.75rem lh-1.5rem">
          {{ t('footer.introduce') }}
        </p>

        <p class="row gap-3.125rem mt-3.125rem">
          <span>{{ t('footer.contactUS.title') }}</span>
          <a href="mailto:partnership@eggor.io" class="ml--2rem">{{ t('footer.contactUS.email') }}</a>
          <a href="https://www.eggor.org">{{ t('footer.contactUS.website') }}</a>
          <a href="mailto:partnership@eggor.io">{{ t('footer.contactUS.support') }}</a>
          <!-- <router-link :to="{ name: 'Support' }">
            {{ t('footer.contactUS.support') }}
          </router-link> -->
        </p>
      </div>

      <div class="grow">
        <p class="lh-1.5rem">
          {{ t('footer.joinCommunity.title') }}
        </p>

        <div class="row gap-1.875rem mt-1.875rem">
          <a href="https://twitter.com/EggorMeta" target="_blank" class="w-3.5rem h-3.5rem col items-center justify-center rounded bg-[rgba(0,0,0,0.1)]">
            <span class="i-carbon-logo-twitter text-1.8rem" />
          </a>

          <a href="https://discord.gg/ByzjfyZTGN" target="_blank" class="w-3.5rem h-3.5rem col items-center justify-center rounded bg-[rgba(0,0,0,0.1)]">
            <span class="i-carbon-logo-discord text-1.8rem" />
          </a>

          <a href="https://t.me/eggor_official" target="_blank" class="w-3.5rem h-3.5rem col items-center justify-center rounded bg-[rgba(0,0,0,0.1)]">
            <span class="i-bi-telegram text-1.5rem" />
          </a>

          <a href="https://mirror.xyz/0x7126a4b854Be54762d78CDFE05cF442f69771258" target="_blank" class="w-3.5rem h-3.5rem col items-center justify-center rounded bg-[rgba(0,0,0,0.1)]">
            <img loading="lazy" src="/icons/mirror.png" class="w-1.8rem h-2.1rem mt-0.3rem object-contain">
          </a>
        </div>
      </div>
    </div>

    <svg stroke="#DFDDDB" xmlns="http://www.w3.org/2000/svg" class="mt-4.375rem stroke-1px" height="2" viewBox="0 0 1592 2" fill="none">
      <path d="M1 1H1591" stroke="#DFDDDB" stroke-linecap="square" />
    </svg>

    <div class="copyright h-4rem line-height-4rem justify-center row gap-2.5rem">
      <span>{{ t('footer.copyright') }}</span>
      <span>{{ t('footer.privacyPolicy') }}</span>
      <span>{{ t('footer.terms') }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rel-col {
  width: 21.45rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.title {
  color: #191715;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
}
.link {
  color: #6E6C6B;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}
.rel-icon {
  width: 1.875rem;
  height: 1.875rem;
}
.rel-icon-mirror {
  background: url(/icons/mirror.png) no-repeat;
  background-position: center;
  background-size: contain;
}
.copyright {
  color: #6E6C6B;
  font-size: 0.875rem;
}
</style>
