<script setup lang="ts">
import { useUserStore } from '@eggor/vue-shared'
import { useAccount } from 'use-wagmi'
import TempAvatar from '~/assets/temp/avatar.png'
import { NavHeaderHeight, PageTransitionDuration } from '~/constants'

const props = defineProps({
  mode: null
})

const store = useWebsiteAppStore()
const { t } = useI18n()
const route = useRoute()
const { address } = useAccount()
const userStore = useUserStore()
const token = toRef(userStore, 'token')
const space = computed(() => userStore.user?.dept.deptId === 204 ? 'AgentUserSpace' : 'UserSpace')
</script>

<template>
  <div :style="{ height: NavHeaderHeight }" class="nav-header row items-center" :class="props.mode">
    <router-link :to="{ name: 'Home' }">
      <layouts-default-header-eggor-logo :mode="props.mode" />
    </router-link>

    <div class="ml-6.25rem gap-3.75rem inline-row">
      <router-link :to="{ name: 'Explore' }" class="router-item" active-class="active" font-500>
        {{ t("header.explore") }}
      </router-link>
      <router-link :to="{ name: 'Community' }" class="router-item" active-class="active" font-500>
        {{ t("header.community") }}
      </router-link>
      <!-- <router-link :to="{ name: 'Auction' }" class="router-item" active-class="active" font-500>
        {{ t("header.auction") }}
      </router-link> -->
      <router-link v-show="route.name !== 'Search'" :to="{ name: 'Search' }" class="hidden lg:inline">
        <layouts-default-header-search-bar w-21.3125rem :ghost="mode === 'white'" />
      </router-link>
    </div>

    <div class="flex-1" />

    <div class="inline-row gap-1.87rem shrink-0">
      <router-link v-show="route.name !== 'Search'" :to="{ name: 'Search' }" class="inline lg:hidden">
        <span class="i-icon-park-outline-search text-1rem vertical-middle" :style="{ color: props.mode }" />
      </router-link>

      <router-link :to="{ name: 'LetterBox' }">
        <n-badge :value="store.noticeUnreadCount.total">
          <span class="i-icon-park-outline-message text-1rem vertical-top" :style="{ color: props.mode }" />
        </n-badge>
      </router-link>

      <div class="cursor-pointer inline-row flex-gap-0.375rem" @click="store.$patch({ showWalletConnector: true })">
        <span v-if="address && token">{{ `${address?.slice(0, 6)}...${address?.slice(-4)}` }}</span>
        <template v-else>
          <span class="i-icon-park-outline-wallet text-1rem vertical-top" :style="{ color: props.mode }" />
          <span class="lh-1.375rem">{{ t('header.wallet') }}</span>
        </template>
      </div>

      <router-link v-if="userStore.id " :to="{ name: space, params: { id: userStore.id } }">
        <img loading="lazy" :src="userStore.avatar ?? TempAvatar" class="rounded-2rem w-2rem h-2rem object-cover">
      </router-link>

      <layouts-default-header-language-switch />
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
}
.nav-header {
  transition: color ease;
  transition-duration: v-bind(PageTransitionDuration);
  &.white {
    color: white;
  }
}

.router-item {
  opacity: 0.8;
}
.router-item.active {
  font-weight: 900;
  opacity: 1;
}
</style>
