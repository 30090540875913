<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'UserCommunityManage',
})
const route = useRoute<'MyUserProfile'>()
const uid = route.params.id
// TODO: change api
const communityIdentity = WebsiteApi.useMyCommunityIdentity()
</script>

<template>
  <e-remote :result="communityIdentity">
    <template #default="{ data }">
      <profile-community-management-status
        v-if="data.value?.data && data.value?.data?.status !== 3"
        :identity="data.value!.data!"
      />
      <profile-community-management-community-tabs v-else :user-id="uid" />
    </template>
  </e-remote>
</template>

<style scoped>

</style>
