<script setup lang="ts">
definePage({ name: 'AlbumPage' })
</script>

<template>
  <profile-album />
</template>

<style scoped>

</style>
