<script setup lang="ts" generic="T extends UseFetchReturn<any>">
import type { UseFetchReturn } from '@vueuse/core'

defineOptions({
  inheritAttrs: false,
})

const props: {
  result: T
  spinStyle?: Partial<CSSStyleDeclaration>
  emptyStyle?: Partial<CSSStyleDeclaration>
  testEmpty?: (result: T) => boolean
} = defineProps({
  result: null,
  spinStyle: null,
  emptyStyle: null,
  testEmpty: { type: Function }
})

defineSlots<{ default(props: T): any; empty(): any }>()

type DataType = T extends UseFetchReturn<infer R> ? R : never

const result = computed(() => props.result)
const { t } = useI18n()

const error = computed(() => result.value.error.value)
const errorMsg = computed(() => error.value?.message ?? error.value?.toString())
const data = computed(() => result.value.data.value)
const isFetching = computed(() => result.value.isFetching.value)
function handleReload() {
  result.value.execute(false)
}
onUserChanged(() => result.value.execute?.(false))
</script>

<template>
  <template v-if="isFetching && !data">
    <div class="p-2rem text-center relative" v-bind="$attrs">
      <n-spin
        size="large"
        class="p-2rem"
        :description="t('loading')"
        :style="props.spinStyle ?? {}"
      />
    </div>
  </template>

  <template v-else-if="error && !data">
    <div
      class="col gap-2rem items-center py-2rem"
      v-bind="$attrs"
    >
      <span class="i-ph-circle-wavy-warning text-4rem" />

      <p>
        {{ errorMsg }}
      </p>

      <n-button size="small" @click="handleReload">
        {{ t('reload') }}
      </n-button>
    </div>
  </template>

  <template v-else>
    <n-spin
      v-bind="$attrs"
      :show="isFetching"
      :style="props.spinStyle ?? {}"
      :description="t('loading')"
    >
      <slot v-if="typeof props.testEmpty == 'function' ? props.testEmpty(result) : !data" name="empty">
        <n-empty
          :style="props.emptyStyle ?? {
            padding: '0 2rem',
          }"
        />
      </slot>
      <!-- eslint-disable-next-line vue/no-extra-parens -->
      <slot v-else v-bind="(result as DataType)" st="1" />
    </n-spin>
  </template>
</template>

<style scoped>
:deep(.n-spin-content) {
  width: 100%;
  height: 100%;
}
</style>
