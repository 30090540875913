<script setup lang='ts'>
import { type AgentForm, WebsiteApi, useUserStore } from '@eggor/vue-shared'
import type { FormInst, FormRules } from 'naive-ui'
import type FragmentAgentBasicInfo from '~/components/legalize/FragmentAgentBasicInfo.vue'

definePage({
  name: 'LegalizeAgent',
})

const { t } = useI18n()
const message = useMessage()
const router = useRouter()

const userStore = useUserStore()
const agentIdentityResult = WebsiteApi.useAgentInfo(computed(() => userStore.user ? userStore.user.userId : 0), { immediate: false })

onActivated(() => {
  if (userStore.user) {
    agentIdentityResult.execute()
  }
})

const queryParams = ref<AgentForm>({
  fullName: '',
  contactInformation: '',
  nationality: '',
  placeOfResidence: '',
  company: '',
  identityCardUrl: [],
  userId: Number(userStore.user?.userId),
  gender: '',
  registeredResidence: '',
  idNumber: '',
  status: null,
  domain: [],
  employmentExperience: '',
  otherMaterials: [],
  personalData: '',
  personalDomain: '',
  service: [],
})
const { execute: updateAgentProfile, data: updateAgentData } = WebsiteApi.useUpdateAgentProfile<AgentForm>(computed(() => queryParams.value), { immediate: false })

watch(agentIdentityResult.data, (agentData) => {
  if (agentData && agentData.data) {
    queryParams.value = agentData.data as any as AgentForm
  }
})

const formRef = ref<FormInst | null>(null)
const { data, isFetching, execute } = WebsiteApi.useApplyAgent(queryParams)
watch([data, updateAgentData], ([data1, data2]) => {
  if (data1 || data2) {
    // message.success(t(submit.value ? 'legalize.applicationSubmitted' : 'legalize.applicationSubmitted'))
    message.success('申请已发送')
    router.replace({ name: 'ProfileAuthentication' })
  }
})
const basicInfoRef = ref<InstanceType<typeof FragmentAgentBasicInfo>>()
function handleValidateButtonClick(e: MouseEvent) {
  e.preventDefault()

  // console.log(basicInfoRef.value!.services)
  // console.log(queryParams.value)
  // return
  formRef.value?.validate((errors) => {
    if (errors) {
      return null
    }
    // console.log(agentIdentityResult.data.value)
    // [...basicInfoRef.value!.services.values()].filter(item => item.url || item.title || item.content)
    queryParams.value.service = [...basicInfoRef.value!.services.values()].filter(item => item.url || item.title || item.content)
    if (agentIdentityResult.data.value?.data?.status === 2) {
      queryParams.value.status = 1
      updateAgentProfile()
    }
    else {
      queryParams.value.status = 1
      execute()
    }
  })
}
const shouldDisplayButton = computed(() => {
  // console.log(agentIdentityResult)
  if (agentIdentityResult.data.value?.data?.status === 1 || agentIdentityResult.data.value?.data?.status === 3) {
    return false
  }
  return true
})
const rules = computed<FormRules>(() => ({
  fullName: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.name') }),
    },
  ],
  gender: [
    {
      // required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.name') }),
      validator: () => {
        if (queryParams.value.gender === null) {
          return false
        }
      },
    },
  ],
  contactInformation: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.tel') }),
    },
  ],
  nationality: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.tel') }),
    },
  ],
  idNumber: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.tel') }),
    },
  ],
  placeOfResidence: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: '居住地' }),
    },
  ],
  registeredResidence: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: '户籍居住地' }),
    },
  ],
  identityCardUrl: [
    {
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: '身份审批材料' }),
      validator: () => {
        if (queryParams.value.identityCardUrl.length === 0) {
          return false
        }
      },
    },
  ],
  serviceUrl: [
    {
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput'),
      validator: () => {
        if (queryParams.value.identityCardUrl.length === 0) {
          return false
        }
      },
    },
  ],
  serviceTitle: [
    {
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput'),
      validator: () => {
        if (queryParams.value.identityCardUrl.length === 0) {
          return false
        }
      },
    },
  ],
  identityContent: [
    {
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput'),
      validator: () => {
        if (queryParams.value.identityCardUrl.length === 0) {
          return false
        }
      },
    },
  ],

}))
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <n-layout>
      <n-layout-header
        class="font-bold text-1.625rem text-[#403f3e] lh-1.875rem"
      >
        {{ t("legalize.agent.title") }}
      </n-layout-header>
      <div
        class="mt-5 description lh-1rem"
      >
        {{ t('legalize.artist.notice') }}
      </div>
      <n-layout-content class="max-w-62.5rem w-100%">
        <n-form
          ref="formRef"
          :disabled="isFetching || [1, 3].includes(agentIdentityResult.data.value?.data?.status ?? 0)"
          :model="queryParams"
          :rules="rules"
          class="col gap-1.875rem"
        >
          <!-- 基本信息 -->
          <section class="col gap-1.25rem">
            <div
              class="text-1rem font-bold lh-1rem lh-2.5rem mt-3.125rem "
            >
              {{ t('legalize.basicInfo.title') }}
            </div>

            <legalize-fragment-agent-basic-info ref="basicInfoRef" v-model="queryParams" />
          </section>
        </n-form>
        <div class="mt-6.25rem">
          <!--    <n-button
            :loading="isFetching "
            :disabled="isFetching "
            type="default"
            @click="(e) => handleValidateButtonClick(e)"
          >
            {{ t('save') }}
          </n-button> -->

          <n-button
            :class="shouldDisplayButton ? '' : 'hidden'"
            :loading="isFetching "
            :disabled="isFetching "
            class="ml-10"
            type="primary"
            @click="(e) => handleValidateButtonClick(e)"
          >
            {{ t('legalize.send') }}
          </n-button>
        </div>
      </n-layout-content>
    </n-layout>
  </layouts-wrapper>
</template>

<style scoped lang="scss">
:deep(.n-form-item-label__text) {
  font-weight: bold;
}
</style>
