<script setup lang="ts">
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'
import type { FormInst } from 'naive-ui'
import { useAccount, useDisconnect, useSignMessage } from 'use-wagmi'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const messager = useMessage()
const { address } = useAccount()
const { disconnect } = useDisconnect()
const userStore = useUserStore()
const devMode = import.meta.env.EGGOR_ENV === 'development'
const formRef = ref<FormInst>()
const form = ref({
  address: address.value,
  nickname: '',
})
const invitation = computed(() => route.query.invitation as string | undefined)

const { data, isFetching, error, execute } = WebsiteApi.useIsUserExists(computed(() => address.value ?? ''), {
  refetch() {
    return !!address.value
  },
})
const { data: nonce, isFetching: isNonceFetching, execute: getNonce } = WebsiteApi.useNonce(computed(() => address.value ?? ''), location.origin, { immediate: false })
const { data: signature, isLoading: isMessageSigning, signMessageAsync, error: signMessageError } = useSignMessage()
const { data: signInResult, execute: signIn, isFetching: isSigning } = WebsiteApi.useLogin(computed(() => ({
  address: form.value.address ?? '',
  signature: signature.value ?? '',
  nickname: form.value.nickname,
  invitationCode: invitation.value,
})))
// const { data: loginInfo, isFetching: isLoginInfoFetching, execute: getLoginInfo } = AdminApi.useInfo({ immediate: false })
const { showWalletConnector } = storeToRefs(useWebsiteAppStore())
const isUserExists = computed(() => data.value?.data)

watch(address, (address) => {
  form.value.address = address
})

watch(signMessageError, (err: any) => {
  if (err && err.code === 4001)
    messager.error(t('core.signInModal.signInRejected'))
  else err && messager.error(err.message ?? err.toString())
})

watch(signInResult, (result) => {
  if (result) {
    userStore.setToken(result!.token)
    if (invitation.value)
      router.replace({
        ...route,
        query: undefined,
      })
  }
})
// watch(loginInfo, (loginInfo) => {
//   if (loginInfo) {
//     userStore.setInfo(loginInfo!.user, loginInfo.permissions)
//     showWalletConnector.value = false
//   }
// })

function handleSignIn() {
  formRef.value?.validate(async (err) => {
    if (err)
      return
    await getNonce(true)
    await signMessageAsync({ message: nonce.value?.nonce ?? '' })
    await signIn(true).catch((err) => {
      messager.error(err.message)
    })
    showWalletConnector.value = false
    // await getLoginInfo(true)
  }).catch(() => {})
}
</script>

<template>
  <n-spin :show="isFetching">
    <n-form ref="formRef" class="col gap-1rem p1" :model="form" :rules="{ nickname: { required: !isUserExists, trigger: ['input', 'blur'] } }">
      <n-form-item :label="`${t('core.signInModal.yourAddress')} ${(devMode ? ' (仅开发环境可编辑,管理员钱包可登录任意账号)' : '')}`">
        <n-input v-model:value="address" :disabled="!devMode" />
      </n-form-item>

      <n-form-item v-show="isUserExists === false && !error" :label="t('core.signInModal.nickname')" path="nickname">
        <n-input v-model:value="form.nickname" :loading="isSigning || isMessageSigning || isNonceFetching" :placeholder="t('core.signInModal.inputNickName')" />
      </n-form-item>

      <div class="row gap-1rem">
        <n-button @click="disconnect()">
          {{ t('core.signInModal.disconnect') }}
        </n-button>
        <n-button :disabled="error" :loading="isSigning || isMessageSigning || isNonceFetching" type="primary" class="grow" @click="handleSignIn">
          {{ t('core.signInModal.signIn') }}
        </n-button>
      </div>

      <n-button v-if="error" class="w-full" type="primary" @click="() => execute()">
        {{ t('retry') }}
      </n-button>
    </n-form>
  </n-spin>
</template>

<style scoped>

</style>
