<script setup lang="ts">
import { AdminApi, addFetchErrorListener, clearErrorListeners, setDevMode, useAppStore, useUserStore } from '@eggor/vue-shared'
import type { MessageReactive } from 'naive-ui'
import { loadLanguageAsync } from './modules/i18n'

const router = useRouter()
const messager = useMessage()
const loadingBar = useLoadingBar()
const { t } = useI18n()
const { locale } = storeToRefs(useAppStore())
const isDark = useDark()
const userStore = useUserStore()
const websiteStore = useWebsiteAppStore()
const messages = reactive<MessageReactive[]>([])

const {
  data: userInfo,
  execute: getUserInfo,
  isFetching: isFetchingUserInfo,
} = AdminApi.useInfo({
  immediate: false,
  refetch: false,
})

onUserChanged(() => {
  if (userStore.token)
    fetchUserInfo()
})
onMounted(() => {
  setDevMode(import.meta.env.DEV)
  isDark.value = false
  fetchUserInfo()
})
watchPostEffect(() => {
  loadLanguageAsync(locale.value).catch(() => {
    locale.value = 'en-US'
  })
})
watch(userInfo, (userInfo) => {
  if (userInfo) {
    userStore.setInfo(userInfo.user, userInfo?.permissions)
  }
})
tryOnScopeDispose(router.beforeEach(() => {
  loadingBar.start()
}))
tryOnScopeDispose(router.afterEach(() => {
  loadingBar.finish()
}))
tryOnScopeDispose(addFetchErrorListener((ctx) => {
  if (ctx.error?.name === 'AbortError')
    return
  console.error('fetch error', { ...ctx })

  let content = ctx.error.message ?? ctx.error.toString()

  if (!userStore.token)
    return

  if (ctx.data && ctx.data.code === 401) {
    content = t('core.signInModal.expired')
    userStore.logout()
    websiteStore.showWalletConnector = true
  }
  if (ctx.response?.status === 500) {
    content = t('networkException')
  }

  let _index = messages.findIndex(_ => _.content === content)
  if (_index >= 0)
    messages[_index].destroy()
  _index = messages.push(messager.error(content, {
    duration: 7000,
    keepAliveOnHover: true,
    closable: true,
    onClose() {
      messages.splice(_index, 1)
    },
  }))
}))
function fetchUserInfo() {
  if (isFetchingUserInfo.value)
    return
  getUserInfo(false)
}
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    clearErrorListeners()
  })
}

function onMentionClicked(event: any) {
  if (event?.value?.denotationChar) {
    const denotationChar = event.value.denotationChar
    // const value = event.value.value
    // const id = event.value.id
    switch (denotationChar) {
      case '＠':
      case '@':
        event.value.id && router.push({ name: 'UserSpace', params: { id: event.value.id } })
        break
      case '＃':
      case '#':
        event.value.id && router.push({ name: 'TagPosts', params: { name: event.value.value } })
        break
    }
    // router.push({name: ''})
  }
}
watchPostEffect((onDispose) => {
  window.addEventListener('mention-clicked', onMentionClicked, false)
  onDispose(() => window.removeEventListener('mention-clicked', onMentionClicked))
})
</script>

<template>
  <main>
    <RouterView />
  </main>

  <!-- 预载入图标 -->
  <i class="i-ph-music-note-bold hidden" aria-hidden="true" />
  <i class="i-line-md-loading-twotone-loop hidden" aria-hidden="true" />
</template>
