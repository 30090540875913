import { UseWagmiPlugin, configureChains, createConfig, mainnet } from 'use-wagmi'
import { publicProvider } from 'use-wagmi/providers/public'
import { WalletConnectConnector } from 'use-wagmi/connectors/walletConnect'
import { MetaMaskConnector } from 'use-wagmi/connectors/metaMask'
import type { UserModule } from '~/types'
import icon from '~/assets/icons/favicon.png'

export const install: UserModule = (app) => {
  const { chains, publicClient, webSocketPublicClient } = configureChains([mainnet], [publicProvider()])
  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [
      new MetaMaskConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: import.meta.env.EGGOR_METAMASK_KEY,
          isNewChainsStale: false,
          metadata: {
            name: 'Eggor',
            description: 'Eggor.io',
            url: 'https://eggor.io',
            icons: [icon],
          },
        },
      }),
      // new InjectedConnector({ chains }),
    ],
  })
  app.use(UseWagmiPlugin, config)
}
