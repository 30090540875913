<script setup lang="ts">
import BasicSetting from '~/components/event/BasicSetting.vue'
import MethodJoined from '~/components/event/MethodJoined.vue'
import QualificationJoined from '~/components/event/QualificationJoined.vue'
import Reward from '~/components/event/Reward.vue'

definePage({
  name: 'EventCreation',
})
const { t } = useI18n()
const panels = [
  { tab: 'profile.activityManage.panels.basicSetting.title', name: BasicSetting },
  { tab: 'profile.activityManage.panels.methodJoined.title', name: MethodJoined },
  { tab: 'profile.activityManage.panels.qualificationJoined.title', name: QualificationJoined },
  { tab: 'profile.activityManage.panels.reward.title', name: Reward },
] as const

const currentPanel = ref<typeof panels[number]['tab']>(panels[0].tab)
</script>

<template>
  <layouts-wrapper class="pt-70px">
    <div class="text-26px mb-30px">
      {{ t('profile.activityManage.createActivity') }}
    </div>
    <div class=" relative">
      <n-tabs
        type="segment"
        :default-value="currentPanel"
        class="w-924pxx"
        tab-style="height:2.8125rem;padding:0;"
      >
        <n-tab-pane
          v-for="{ tab, name } in panels"
          :key="tab"
          :name="tab"
          :tab="t(tab)"
          class="mr-1.25rem w-full"
        >
          <template #tab>
            <n-button
              class="h-2.8125rem p-0 w-full"
              quaternary
              :bordered="false"
              :focusable="false"
              :class="currentPanel === tab ? 'active' : ''"
              @click="currentPanel = tab"
            >
              {{ t(tab) }}
            </n-button>
          </template>
          <template #default>
            <component :is="name" />
          </template>
        </n-tab-pane>
      </n-tabs>
      <div class="absolute right-0 top-0 h-65px flex items-center text-sm ">
        <n-button class="mr-1.25rem w-100px h-40px">
          <span class="text-sm">{{ t('profile.activityManage.preview') }}</span>
        </n-button>
        <n-button class="mr-1.25rem w-100px h-40px">
          <span class="text-sm">    {{ t('profile.activityManage.save') }}</span>
        </n-button>
        <n-button class="mr-1.25rem w-100px h-40px">
          <span class="text-sm">   {{ t('profile.activityManage.publish') }}</span>
        </n-button>
      </div>
    </div>
  </layouts-wrapper>
</template>

<style scoped>
.active {
  background-color: #DFDDDB!important;
}
:deep .n-tabs-rail {
  width:50%;
}
:deep() .n-tabs-tab__label {
 font-size: 1rem;
}
:deep() .n-tabs-rail {
 background: #fff;
 gap: 20px;
 justify-content: flex-start;
 height: 65px;
}

:deep() .n-tabs-tab__label {
 color: black;

 width: 100%;
}
:deep() .n-tab-pane{
  padding: 0!important;
}
</style>
