<script setup lang="ts">
import '@vueup/vue-quill/dist/vue-quill.snow.css'

definePage({
  name: 'PostCreation',
})
const route = useRoute()
const router = useRouter()
const communityId = computed(() => route.query.communityId as string | undefined)
</script>

<template>
  <layouts-wrapper class="pt-2.5rem col gap-2rem max-w-50rem m-auto">
    <community-post-creation
      :community-id="communityId!"
      @published="(postId) => router.replace({ name: 'Post', params: { id: postId } })"
    />
  </layouts-wrapper>
</template>
