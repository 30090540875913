<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage(
  {
    name: 'ArtistList',
  },
)
const { t } = useI18n()
const current = ref(1)
const nickName = ref<string>('')
const options = ref([
  { label: t('explore.user-list.top'), value: 1 },
  { label: t('explore.user-list.all'), value: '2' },
  { label: t('explore.user-list.trend'), value: 2 },
  { label: t('explore.user-list.newest'), value: 3 },
])
const paging = ref({ pageSize: 16, pageNum: 1 })

// const isRecommend = computed(() => current.value === 1)
const params = computed(() => {
  switch (current.value) {
    case 1:
      return { recommended: true }
      break
    case 2:
      return {}
    case 3:
      return { newest: true }
    default:
      return {}
      break
  }
})
const result = WebsiteApi.useUsersByNickName(computed(() => nickName.value ?? ''), 202, paging, params, { refetch: true })

watch(current, () => {
  if (current.value) {
    result.execute()
  }
})
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <div>
      <n-input v-model:value="nickName" autosize class="w-15rem mr-1.25rem align-bottom" :placeholder="t('explore.user-list.search')" clearable>
        <template #prefix>
          <span class="i-icon-park-outline-search" />
        </template>
      </n-input>

      <n-popselect v-model:value="current" :options="options" trigger="click" class="w-150px">
        <n-button class="min-w-150px">
          {{ options[current - 1].label }}
          <span class="i-carbon-chevron-down ml-.5rem" />
        </n-button>
      </n-popselect>
    </div>

    <e-remote-paging :paging="paging" :result="result" @update:paging="(newPaging) => paging.pageNum = newPaging.pageNum!">
      <template #default="{ rows }">
        <div class="grid grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] gap-1.25rem mt-3.125rem">
          <explore-user-card v-for="collector in rows" :key="collector.userId" :user="collector" />
        </div>
      </template>
    </e-remote-paging>
  </layouts-wrapper>
</template>

<style scoped>

</style>
