<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'AgentInfo',
})
const route = useRoute<'AgentUserSpace'>()
const uid = route.params.id
const { t } = useI18n()
const userInfoResult = WebsiteApi.useAgentInfo(uid)
useAdaptiveRefetch(userInfoResult)
</script>

<template>
  <e-remote class="mt-1.875rem" :result="userInfoResult">
    <template #default="{ data }">
      <div class="col gap-1.25rem w-full">
        <div>
          <p class="text-1rem font-bold mb-1rem">
            {{ t('agent.info.domain') }}
          </p>
          <n-tag v-for="(v, i) in data.value?.data?.domain" :key="i" class="mr-1.25rem">
            {{ v }}
          </n-tag>
        </div>
        <div>
          <p class="text-1rem font-bold mb-1rem">
            {{ t('agent.info.personalDomain') }}
          </p>
          <p class="line-clamp-3 h-40px">
            {{ data.value?.data?.personalDomain }}
          </p>
        </div>
        <div>
          <p class="text-1rem font-bold mb-1rem">
            {{ t('agent.info.personalData') }}
          </p>
          <p class="line-clamp-3 h-40px">
            {{ data.value?.data?.personalData }}
          </p>
        </div>
        <div>
          <p class="text-1rem font-bold mb-1rem">
            {{ t('agent.info.contactInformation') }}
          </p>
          <p>
            {{ data.value?.data?.contactInformation }}
          </p>
        </div>
        <div>
          <p class="text-1rem font-bold mb-1rem">
            {{ t('agent.info.service') }}
          </p>
          <div class="grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))] gap-1.25rem">
            <agent-user-space-service v-for="(v, i) in data.value?.data?.service" :key="i" :service-info="v" />
          </div>
        </div>
      </div>
    </template>
  </e-remote>
</template>

<style scoped>
/* active */
:deep() .n-carousel .n-carousel__dots.n-carousel__dots--dot .n-carousel__dot.n-carousel__dot--active {
  background-color: black;
}
/* non-active */
:deep() .n-carousel .n-carousel__dots.n-carousel__dots--dot .n-carousel__dot {
  background-color: #ccc;
}
</style>
