<script setup lang="ts">
import { NavHeaderHeight } from '~/constants'

definePage({
  name: 'LetterBox',
  meta: {
    noContentWidthRestrictions: true,
    noContentPadding: true,
  },
  redirect: { name: 'LetterBoxSystem' },
})

const { t } = useI18n()

const { noticeUnreadCount } = useWebsiteAppStore()
const unreadCounts = computed(() => ({
  LetterBoxComments: noticeUnreadCount.comment,
  LetterBoxLikes: noticeUnreadCount.like,
  LetterBoxSystem: noticeUnreadCount.system,
  LetterBoxAts: noticeUnreadCount.at,
}))
const routeMap = computed(() => ({
  LetterBoxSystem: t('letter-box.system.title'),
  LetterBoxComments: t('letter-box.comments.title'),
  LetterBoxLikes: t('letter-box.likes.title'),
  LetterBoxAts: t('letter-box.ats.title'),
  LetterBoxNews: t('letter-box.news.title'),
}))
const route = useRoute()
const router = useRouter()

const noticeStore = useNoticeStore()
function returnFirst() {
  noticeStore.pagingNum = 1
}
</script>

<template>
  <div class="letter-box relative gap-0.94rem" :style="{ marginTop: NavHeaderHeight }">
    <div class="absolute inset-0 " />

    <div class="sider w-23.4375rem">
      <div class="h-6.25rem line-height-6.25rem text-center font-400 text-1rem" style="background-color: rgba(255, 255, 255, 0.75);">
        {{ t('letter-box.title') }}
      </div>

      <div class="py-2.06rem px-3.44rem">
        <n-list hoverable clickable class="bg-transparent">
          <n-list-item
            v-for="routeName in Object.keys(routeMap)"
            :key="routeName"
            @click="router.replace({ name: routeName as 'LetterBox', query: { noTransition: 'true' } })"
          >
            <n-badge :max="99" :value="unreadCounts[routeName as 'LetterBoxSystem']">
              <div class="pr-3 text-[#191715]" :class="{ active: route.name === routeName }">
                {{ routeMap[routeName as 'LetterBoxSystem'] }}
              </div>
            </n-badge>
          </n-list-item>
        </n-list>
      </div>
    </div>

    <div class="content gap-0.94rem w-full max-w-75rem col items-stretch">
      <div class="row items-center shrink-0 py-1.25rem px-4.38rem font-400 text-1rem rounded bg-[rgba(255,255,255,0.85)] shadow-sm">
        <span> {{ routeMap[route.name as 'LetterBoxSystem'] }}</span>
        <n-button quaternary :focusable="false" class="hover:bg-transparent!" :disabled="noticeStore.pagingNum === 1" @click="returnFirst">
          <span class="i-icon-park-outline-return text-gray-500" />
        </n-button>
      </div>

      <div class="grow min-h-80dvh">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.letter-box {
  background-image: url("/images/letter_box_background.jpg");
  padding-top: 2rem;
  padding-bottom: 4.25rem;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;

  .sider {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    flex-shrink: 0;

    .active {
      color: #FF8D0D;
    }

    :deep() {
      .n-list-item__divider {
        background-color: hsla(30, 6%, 87%, 1) !important;
      }
      .n-list-item:last-child {
        .n-list-item__divider {
          display: none !important;
        }
      }
      .n-list-item__main {
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .content {
    padding: 1.81rem 1.87rem;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
  }
}
</style>
