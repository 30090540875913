<script setup lang="ts">
import { LRUCache } from 'lru-cache'
import type { RouteLocationNormalizedLoaded } from 'vue-router/auto'
import { ContentWidth, IndexCoverHeight, MaxContentWidth, NavHeaderHeight, NavHeaderIndex, PageTransitionDuration, TopLevelRouteBypassNames } from '~/constants'

const { showWalletConnector } = storeToRefs(useWebsiteAppStore())

const route = useRoute()
const router = useRouter()
// 从当前路由meta中获取，是否内容区扩展到导航栏下
const contentExtendBodyBehindHeader = computed(() => route.meta.extendBodyBehindHeader === true)

// 是否启用原生滚动条
const enableNativeScrollbar = ref(false)
// Layout (内容区)
const layoutRef = ref<any | null>(null)
// 首页Cover的高度
const indexCoverHeight = useRemToPx(IndexCoverHeight)
// 导航栏的高度
const navHeaderHeight = useRemToPx(NavHeaderHeight)
// 导航栏显示模式，白色字体或黑色字体(及图标)
const navHeaderMode = ref<'black' | 'white'>(contentExtendBodyBehindHeader.value ? 'white' : 'black')
// 遵循用户设置去除路由动画
// const reducedMotion = usePreferredReducedMotion()
const reducedMotion = ref('reduce')
// 路由动画时长ms
const animationMs = computed(() => reducedMotion.value === 'reduce' ? '0ms' : PageTransitionDuration)

const scrollTop = ref(0)

function onLayoutScroll(e: Event) {
  if (e && e.type === 'scroll') {
    const ele = e.target as HTMLElement
    scrollTop.value = ele.scrollTop
    if (contentExtendBodyBehindHeader.value) {
      navHeaderMode.value = ele.scrollTop >= (indexCoverHeight.value - navHeaderHeight.value) ? 'black' : 'white'
    }
  }
}

// 记录滚动位置
watch(() => route.path, resetScrollPosition)
function resetScrollPosition() {
  navHeaderMode.value = contentExtendBodyBehindHeader.value ? 'white' : 'black'
  // 可在路由元数据配置ignoreScrollToTop来禁用默认的回到顶部
  if (route.meta.ignoreScrollToTop)
    return
  layoutRef.value?.scrollTo({ top: 0 })
}
const routeScrollPosMap = new LRUCache<string, number>({ max: 10 })
watch(() => route.fullPath, (fullPath) => {
  // eslint-disable-next-line no-console
  console.info('keep scroll pos onBeforeRouteLeave recover', route.fullPath, routeScrollPosMap.get(fullPath), route.meta.ignoreKeepScrollPosition)
  // 可在路由元数据里配置ignoreKeepScrollPosition来禁用滚动位置保持
  if (route.meta.ignoreKeepScrollPosition)
    return
  if (routeScrollPosMap.has(fullPath)) {
    nextTick(() => layoutRef.value.scrollTo({ top: routeScrollPosMap.get(fullPath) }))
  }
}, { immediate: true })
// onBeforeRouteLeave((to, from, next) => {
//   console.info('keep scroll pos onBeforeRouteLeave', route.fullPath, scrollTop.value)
//   routeScrollPosMap.set(route.fullPath, scrollTop.value)
//   next()
// })
tryOnScopeDispose(router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-console
  console.info('keep scroll pos onBeforeRouteLeave', route.fullPath, scrollTop.value, route.meta.ignoreKeepScrollPosition)
  if (route.meta.ignoreKeepScrollPosition)
    return
  routeScrollPosMap.set(route.fullPath, scrollTop.value)
  next()
}))
onUnmounted(() => {
  routeScrollPosMap.clear()
})

function getKey(route: RouteLocationNormalizedLoaded) {
  const bypassRoute = route.matched.find(r => TopLevelRouteBypassNames.includes(r.name as any))
  if (bypassRoute)
    return JSON.stringify({ params: route.params, name: bypassRoute.name })
  return route.path
}
</script>

<template>
  <NLayout position="absolute">
    <n-layout-header
      class="sticky top-0 inset-x-0 backdrop-blur-2xl"
      :style="{ zIndex: NavHeaderIndex, background: navHeaderMode === 'black' ? 'rgba(255,255,255,0.8)' : 'transparent' }"
    >
      <layouts-default-header
        :mode="navHeaderMode"
        :style="{ maxWidth: MaxContentWidth, width: ContentWidth }"
        class="m-auto"
      />
    </n-layout-header>

    <NLayout
      ref="layoutRef"
      :on-scroll="onLayoutScroll"
      position="absolute"
      :native-scrollbar="enableNativeScrollbar"
      style="background-color: transparent;"
      class="content-wrapper"
    >
      <NLayout>
        <RouterView v-slot="{ Component, route }">
          <template v-if="Component">
            <!-- <transition
              :name="!!route.meta.noTransition || !!route.params.noTransition || !!route.query.noTransition ? '' : 'fade'"
              appear
              :duration="parseInt(animationMs)"
              mode="out-in"
              @after-leave="resetScrollPosition"
            > -->
            <KeepAlive :max="4">
              <component :is="Component" :key="getKey(route as any)" />
            </KeepAlive>
            <!-- <pre>{{ route }}</pre> -->
            <!-- <layouts-default-wrapper :key="route.fullPath"> -->
            <!-- </layouts-default-wrapper> -->
            <!-- </transition> -->
          </template>
        </RouterView>
      </NLayout>

      <n-layout-footer class="pt-4.37rem" style="background-color: transparent;">
        <layouts-default-footer :style="{ maxWidth: MaxContentWidth, width: ContentWidth }" class="m-auto" />
      </n-layout-footer>
    </NLayout>
  </NLayout>

  <sign-in-modal v-model:show="showWalletConnector" class="max-w-140" :trap-focus="false" />
</template>

<style lang="scss" scoped>
.content-wrapper :deep(.n-layout .n-layout-scroll-container) {
  overflow: unset;
}
</style>

<style lang="scss">
.fade-enter-active {
  transition: opacity ease-out;
  transition-duration: v-bind(animationMs);
}
.fade-leave-active {
  transition: opacity ease-in;
  transition-duration: v-bind(animationMs);
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade-enter-active,
  .fade-leave-active {
    transition: none;
  }
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
