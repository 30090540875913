<script setup lang="ts">
definePage({
  name: 'UserSpaceCommunities',
  meta: {
    ignoreScrollToTop: 1,
  },
})
const route = useRoute<'UserSpaceCommunities'>()
const userId = route.params.id
</script>

<template>
  <user-space-communities :key="userId" :user-id="userId" />
</template>

<style scoped>

</style>
