<script setup lang="ts">
definePage({
  name: '404',
})
</script>

<template>
  <layouts-wrapper>
    <h2 class="text-center mt-40 font-bold text-gray text-8xl">
      404
    </h2>
  </layouts-wrapper>
</template>

<style scoped>

</style>
