import { useI18n } from 'vue-i18n'
import type { UseI18nOptions } from 'vue-i18n'
import { parseMultilingual, useMultilingual } from '@eggor/vue-shared'
import type { I18nScheme } from '~/types'

export default function<Options = UseI18nOptions>(_options?: Options) {
  return useI18n<{ message: I18nScheme }>({ useScope: 'global' })
}

export {
  parseMultilingual,
  useMultilingual,
}
