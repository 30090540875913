<script setup lang="ts">
definePage({
  name: 'Support',
})
</script>

<template>
  <layouts-default-wrapper>
    WIP
  </layouts-default-wrapper>
</template>

<style scoped>

</style>
