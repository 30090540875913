import { Quill } from '@vueup/vue-quill'
import type { MagicUrlOptions } from 'quill-magic-url'
import videojs from 'video.js'
import type { UserModule } from '~/types'

export interface HashTag {
  id: string
  title: string
}

export function extractHashtagFromOps(ops: any[]) {
  const _tags = ops.map((op: any) => {
    if (op && 'insert' in op && typeof op.insert === 'object' && 'mention' in op.insert && op.insert.mention && typeof op.insert.mention === 'object' && 'denotationChar' in op.insert.mention) {
      if (op.insert.mention.denotationChar === '#' || op.insert.mention.denotationChar === '＃') {
        return op.insert.mention
      }
    }
    return null
  }).filter(_ => !!_)
  return _tags
}

export const install: UserModule = (_app) => {
  const BlockEmbed = Quill.import('blots/block/embed')

  class AudioBlot extends BlockEmbed {
    static blotName = 'audio'
    static tagName = 'audio'

    static create(url: string) {
      const node = super.create()
      node.setAttribute('src', url)
      node.setAttribute('controls', '')
      return node
    }

    static value(node: any) {
      return node.getAttribute('src')
    }
  }
  Quill.register(AudioBlot)

  class VideoBlot extends BlockEmbed {
    static blotName = 'video'
    static tagName = 'video'

    static create(url: string) {
      const node: HTMLDivElement = super.create()
      node.classList.add('video-js')
      const player = videojs(node, {
        sources: [url],
        aspectRatio: '16:9',
        preload: 'auto',
        controls: true,
        fluid: true,
        autoplay: false,
        playbackRates: [0.5, 1, 1.5, 2],
        playsinline: true,
        controlBar: {
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          liveDisplay: false,
          playbackRateMenuButton: false,
          children: [
            { name: 'playToggle' }, // 播放按钮
            { name: 'progressControl' }, // 播放进度条
            {
              name: 'playbackRateMenuButton',
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: 'volumePanel',
              inline: false,
            },
            {
              name: 'pictureInPictureToggle', // 画中画
            },
            { name: 'FullscreenToggle' },
          ],
        },
      })
      player.el_.setAttribute('video', url)
      return player.el_
    }

    static value(node: any) {
      return node.getAttribute('video')
    }
  }
  Quill.register(VideoBlot)

  const icons = Quill.import('ui/icons')
  icons.audio = '<i class="i-ph-music-note-bold" style="font-size: 14px; vertical-align: top;" aria-hidden="true"></i>'
}

export const DefaultMagicUrlOptions = {
  urlRegularExpression: /(https?:\/\/|www\.)[^\s]+/g,
} as MagicUrlOptions

export const DefaultImageCompressOptions = {
  quality: 0.9,
  maxWidth: 5000,
  maxHeight: 5000,
  debug: import.meta.env.EGGOR_ENV === 'development',
} as ImageCompressOptions
