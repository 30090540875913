<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'ProfileActivity',
})
const { t } = useI18n()
const { data } = WebsiteApi.useNewsetActivities({ pageSize: 6, pageNum: 1 }, { refetch: true })
</script>

<template>
  <div class="mb-1.25rem text-1rem">
    {{ t('profile.activity.title') }}
  </div>
  <hr class="mb-1.25rem">
  <profile-event-card :title="t('profile.activity.card.ongoing')" :activity="data?.rows ?? []" />
  <profile-event-card :title="t('profile.activity.card.coming')" :activity="data?.rows ?? []" />
  <profile-event-card :title="t('profile.activity.card.history')" :activity="data?.rows ?? []" />
</template>

<style scoped>

</style>
