<script setup lang="ts">
// import type { AgentUserModel } from '@eggor/vue-shared'
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'CollaboratingArtists',
})

// const route = useRoute<'AgentUserSpace'>()
// const uid = route.params.id
const userInfoResult = WebsiteApi.useGetMyUsers(0)
</script>

<template>
  <e-remote :result="userInfoResult">
    <template #default="{ data }">
      <div v-if="(data.value?.rows?.length ?? 0) > 0" class="mt-1.25rem grid grid-cols-[repeat(auto-fill,minmax(17rem,1fr))] gap-1.25rem mt-1.875rem mb-2.5rem">
        <div v-for="(v, i) in data.value?.rows" :key="i" class="w-full object-cover">
          <agent-user-space-collaborate :user="v" />
        </div>
      </div>
      <n-empty v-else class="mt-1.875rem" />
    </template>
  </e-remote>
</template>

<style scoped>

</style>
