<script setup lang="ts">
import { useAppStore } from '@eggor/vue-shared'
import type { SelectOption } from 'naive-ui'
import { availableLocales } from '~/modules/i18n'

const { t, locale } = useI18n()
const appStore = useAppStore()

const options = computed(() => availableLocales.map<SelectOption>(l => ({
  label: t(`language.${l}`),
  value: l,
})))
</script>

<template>
  <n-popselect :value="locale" :options="options" trigger="click" @update:value="_ => appStore.locale = _">
    <n-button text class="!text-inherit !transition-inherit text-0.875rem lh-1.375rem">
      {{ t(`language.${locale}`) }}
    </n-button>
  </n-popselect>
</template>

<style scoped>

</style>
