<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'CommunityList',
})

const { t } = useI18n()
const type = ref<1 | 2 >(1)
const sortBy = ref<1 | 3 >(1)
const router = useRouter()
const message = useMessage()
const { paging } = usePaging({ pageSize: 12, pageNum: 1 })
const result = WebsiteApi.useCommunities(type, sortBy, paging, { refetch: true })
const { data: communityIdentityResult, execute: communityIdentity } = WebsiteApi.useMyCommunityIdentity({ immediate: false })
watch(communityIdentityResult, () => {
  if (communityIdentityResult.value) {
    if (communityIdentityResult.value.data) {
      message.info(t('community.home.isHaveCommunity'))
    }
    else
      return router.push({ name: 'CommunityCreation' })
  }
})
</script>

<template>
  <layouts-wrapper>
    <div class="text-1.625rem font-bold pt-2.875rem">
      {{ t('community.list.title') }}
    </div>

    <div class="mt-1.875rem mb-2.625rem inline-row gap-2.5rem">
      <n-button class="!font-bold" size="large" :secondary="type === 1" :tertiary="type !== 1" @click="type = 1">
        {{ t('roles.artist') }}
      </n-button>

      <n-button class="!font-bold ml--2.5rem" size="large" :secondary="type === 2" :tertiary="type !== 2" @click="type = 2">
        {{ t('roles.collector') }}
      </n-button>

      <div class="grow-1" />

      <n-button size="large" type="primary" @click="communityIdentity()">
        {{ t('community.list.createOne') }}
      </n-button>

      <n-select
        v-model:value="sortBy"
        class="w-auto min-w-12rem"
        size="large"
        :options="[{ label: t('sort.byCreationTime'), value: 1 },
                   { label: t('sort.byHot'), value: 2 }]"
        placeholder="排序"
      />
    </div>

    <e-remote-paging
      v-model:paging="paging"
      :result="result"
    >
      <template #default="{ rows }">
        <div class="grid grid-cols-[repeat(auto-fill,minmax(28rem,1fr))] gap-1.25rem">
          <community-card
            v-for="community, index in rows"
            :key="index" :community="community"
          />
        </div>
      </template>
    </e-remote-paging>
  </layouts-wrapper>
</template>
