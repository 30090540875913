<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import type { EffectScope } from 'vue'

const noticeStore = useNoticeStore()
definePage({
  name: 'LetterBoxLikes',
})

const { paging } = usePaging({ pageSize: 5, pageNum: 1 })
const result = WebsiteApi.useNotificationReceivedLikes(paging, { refetch: true })

let dirty = false
const { noticeUnreadCount } = useWebsiteAppStore()
watch(() => noticeUnreadCount.like, () => dirty = true)
let scope: EffectScope

onActivated(() => {
  noticeStore.pagingNum = paging.value.pageNum!
  if (dirty) {
    result.execute()
    dirty = false
  }
  scope = effectScope()
  scope?.run(() => {
    watch(noticeStore, () => {
      paging.value.pageNum = noticeStore.pagingNum
    })
  })
})
onMounted(() => {
  noticeStore.pagingNum = paging.value.pageNum!
})
watch(paging, value => noticeStore.pagingNum = value.pageNum!)

onDeactivated(() => {
  scope?.stop()
})
</script>

<template>
  <e-remote-paging
    v-model:paging="paging"
    :result="result"
  >
    <template #default="{ rows }">
      <n-badge
        v-for="notice, index in rows"
        :key="index"
        :dot="!notice.isRead"
        :offset="[-15, 18]"
        class="block w-full"
      >
        <letter-box-likes-item
          :notice="notice"
        />
      </n-badge>
    </template>
  </e-remote-paging>
</template>
