import _definePage_default_0 from '/tmp/website/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/tmp/website/src/pages/404/[...].vue?definePage&vue'
import _definePage_default_3 from '/tmp/website/src/pages/agent/profile/album.vue?definePage&vue'
import _definePage_default_4 from '/tmp/website/src/pages/agent/profile/community.vue?definePage&vue'
import _definePage_default_5 from '/tmp/website/src/pages/agent/profile/edit.vue?definePage&vue'
import _definePage_default_6 from '/tmp/website/src/pages/agent/profile/event.vue?definePage&vue'
import _definePage_default_7 from '/tmp/website/src/pages/agent/profile/myArtist.vue?definePage&vue'
import _definePage_default_8 from '/tmp/website/src/pages/agent/profile/myCollector.vue?definePage&vue'
import _definePage_default_9 from '/tmp/website/src/pages/agent/profile.vue?definePage&vue'
import _definePage_default_10 from '/tmp/website/src/pages/agent/user-profile/[id]/album.vue?definePage&vue'
import _definePage_default_11 from '/tmp/website/src/pages/agent/user-profile/[id]/community.vue?definePage&vue'
import _definePage_default_12 from '/tmp/website/src/pages/agent/user-profile/[id]/edit.vue?definePage&vue'
import _definePage_default_13 from '/tmp/website/src/pages/agent/user-profile/[id]/event.vue?definePage&vue'
import _definePage_default_14 from '/tmp/website/src/pages/agent/user-profile/[id].vue?definePage&vue'
import _definePage_default_15 from '/tmp/website/src/pages/agent/user-space/[id]/agentInfo.vue?definePage&vue'
import _definePage_default_16 from '/tmp/website/src/pages/agent/user-space/[id]/CollaboratingArtists.vue?definePage&vue'
import _definePage_default_17 from '/tmp/website/src/pages/agent/user-space/[id]/CollaboratingCollectors.vue?definePage&vue'
import _definePage_default_18 from '/tmp/website/src/pages/agent/user-space/[id].vue?definePage&vue'
import _definePage_default_19 from '/tmp/website/src/pages/album/creation.vue?definePage&vue'
import _definePage_default_20 from '/tmp/website/src/pages/album/detail.vue?definePage&vue'
import _definePage_default_21 from '/tmp/website/src/pages/auction/index.vue?definePage&vue'
import _definePage_default_22 from '/tmp/website/src/pages/collection/List.vue?definePage&vue'
import _definePage_default_23 from '/tmp/website/src/pages/collection/nft/[id]/index.vue?definePage&vue'
import _definePage_default_24 from '/tmp/website/src/pages/collection/nft/[id]/detail.vue?definePage&vue'
import _definePage_default_25 from '/tmp/website/src/pages/collection/nft/creation.vue?definePage&vue'
import _definePage_default_26 from '/tmp/website/src/pages/community/index.vue?definePage&vue'
import _definePage_default_27 from '/tmp/website/src/pages/community/[id].vue?definePage&vue'
import _definePage_default_28 from '/tmp/website/src/pages/community/agent-list.vue?definePage&vue'
import _definePage_default_29 from '/tmp/website/src/pages/community/artist-list.vue?definePage&vue'
import _definePage_default_30 from '/tmp/website/src/pages/community/collector-list.vue?definePage&vue'
import _definePage_default_31 from '/tmp/website/src/pages/community/creation.vue?definePage&vue'
import _definePage_default_32 from '/tmp/website/src/pages/community/list.vue?definePage&vue'
import _definePage_default_33 from '/tmp/website/src/pages/community/post/[id].vue?definePage&vue'
import _definePage_default_34 from '/tmp/website/src/pages/community/post/creation.vue?definePage&vue'
import _definePage_default_35 from '/tmp/website/src/pages/event/[id].vue?definePage&vue'
import _definePage_default_36 from '/tmp/website/src/pages/event/creation.vue?definePage&vue'
import _definePage_default_37 from '/tmp/website/src/pages/follow/artists.vue?definePage&vue'
import _definePage_default_38 from '/tmp/website/src/pages/follow/collectors.vue?definePage&vue'
import _definePage_default_39 from '/tmp/website/src/pages/follow/fans.vue?definePage&vue'
import _definePage_default_40 from '/tmp/website/src/pages/follow.vue?definePage&vue'
import _definePage_default_41 from '/tmp/website/src/pages/legalize/agent.vue?definePage&vue'
import _definePage_default_42 from '/tmp/website/src/pages/legalize/artist.vue?definePage&vue'
import _definePage_default_43 from '/tmp/website/src/pages/legalize/collector.vue?definePage&vue'
import _definePage_default_44 from '/tmp/website/src/pages/letter-box/ats.vue?definePage&vue'
import _definePage_default_45 from '/tmp/website/src/pages/letter-box/comments.vue?definePage&vue'
import _definePage_default_46 from '/tmp/website/src/pages/letter-box/likes.vue?definePage&vue'
import _definePage_default_47 from '/tmp/website/src/pages/letter-box/news.vue?definePage&vue'
import _definePage_default_48 from '/tmp/website/src/pages/letter-box/system.vue?definePage&vue'
import _definePage_default_49 from '/tmp/website/src/pages/letter-box.vue?definePage&vue'
import _definePage_default_50 from '/tmp/website/src/pages/profile/activity.vue?definePage&vue'
import _definePage_default_51 from '/tmp/website/src/pages/profile/activity-manage.vue?definePage&vue'
import _definePage_default_52 from '/tmp/website/src/pages/profile/agent.vue?definePage&vue'
import _definePage_default_53 from '/tmp/website/src/pages/profile/authentication.vue?definePage&vue'
import _definePage_default_54 from '/tmp/website/src/pages/profile/community.vue?definePage&vue'
import _definePage_default_55 from '/tmp/website/src/pages/profile/community-manage.vue?definePage&vue'
import _definePage_default_56 from '/tmp/website/src/pages/profile/edit.vue?definePage&vue'
import _definePage_default_57 from '/tmp/website/src/pages/profile/invitation.vue?definePage&vue'
import _definePage_default_58 from '/tmp/website/src/pages/profile/my-nft.vue?definePage&vue'
import _definePage_default_59 from '/tmp/website/src/pages/profile.vue?definePage&vue'
import _definePage_default_60 from '/tmp/website/src/pages/search/index.vue?definePage&vue'
import _definePage_default_61 from '/tmp/website/src/pages/support.vue?definePage&vue'
import _definePage_default_62 from '/tmp/website/src/pages/tag/[name]/post/index.vue?definePage&vue'
import _definePage_default_63 from '/tmp/website/src/pages/user-space/[id]/communities.vue?definePage&vue'
import _definePage_default_64 from '/tmp/website/src/pages/user-space/[id]/events.vue?definePage&vue'
import _definePage_default_65 from '/tmp/website/src/pages/user-space/[id]/highlights.vue?definePage&vue'
import _definePage_default_66 from '/tmp/website/src/pages/user-space/[id]/nfts.vue?definePage&vue'
import _definePage_default_67 from '/tmp/website/src/pages/user-space/[id]/offers.vue?definePage&vue'
import _definePage_default_68 from '/tmp/website/src/pages/user-space/[id].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'Home',
    component: () => import('/tmp/website/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/404',
    /* internal name: '/404' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '404',
        component: () => import('/tmp/website/src/pages/404/[...].vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/agent',
    /* internal name: '/agent' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'profile',
        name: 'AgentProfile',
        component: () => import('/tmp/website/src/pages/agent/profile.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'album',
            name: 'AlbumPage',
            component: () => import('/tmp/website/src/pages/agent/profile/album.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: 'community',
            name: 'AgentCommunityPage',
            component: () => import('/tmp/website/src/pages/agent/profile/community.vue'),
            /* no children */
          },
  _definePage_default_4
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: 'AgentProfileEdit',
            component: () => import('/tmp/website/src/pages/agent/profile/edit.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: 'event',
            name: 'AgentEventPage',
            component: () => import('/tmp/website/src/pages/agent/profile/event.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: 'myArtist',
            name: 'AgentMyArtistPage',
            component: () => import('/tmp/website/src/pages/agent/profile/myArtist.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'myCollector',
            name: 'AgentMyCollectorPage',
            component: () => import('/tmp/website/src/pages/agent/profile/myCollector.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
  _definePage_default_9
  ),
      {
        path: 'user-profile',
        /* internal name: '/agent/user-profile' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'MyUserProfile',
            component: () => import('/tmp/website/src/pages/agent/user-profile/[id].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'album',
                name: 'UserAlbumPage',
                component: () => import('/tmp/website/src/pages/agent/user-profile/[id]/album.vue'),
                /* no children */
              },
  _definePage_default_10
  ),
  _mergeRouteRecord(
              {
                path: 'community',
                name: 'UserCommunityManage',
                component: () => import('/tmp/website/src/pages/agent/user-profile/[id]/community.vue'),
                /* no children */
              },
  _definePage_default_11
  ),
  _mergeRouteRecord(
              {
                path: 'edit',
                name: 'UserEdit',
                component: () => import('/tmp/website/src/pages/agent/user-profile/[id]/edit.vue'),
                /* no children */
              },
  _definePage_default_12
  ),
  _mergeRouteRecord(
              {
                path: 'event',
                name: 'UserEventPage',
                component: () => import('/tmp/website/src/pages/agent/user-profile/[id]/event.vue'),
                /* no children */
              },
  _definePage_default_13
  )
            ],
          },
  _definePage_default_14
  )
        ],
      },
      {
        path: 'user-space',
        /* internal name: '/agent/user-space' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'AgentUserSpace',
            component: () => import('/tmp/website/src/pages/agent/user-space/[id].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'agentInfo',
                name: 'AgentInfo',
                component: () => import('/tmp/website/src/pages/agent/user-space/[id]/agentInfo.vue'),
                /* no children */
              },
  _definePage_default_15
  ),
  _mergeRouteRecord(
              {
                path: 'CollaboratingArtists',
                name: 'CollaboratingArtists',
                component: () => import('/tmp/website/src/pages/agent/user-space/[id]/CollaboratingArtists.vue'),
                /* no children */
              },
  _definePage_default_16
  ),
  _mergeRouteRecord(
              {
                path: 'CollaboratingCollectors',
                name: 'CollaboratingCollectors',
                component: () => import('/tmp/website/src/pages/agent/user-space/[id]/CollaboratingCollectors.vue'),
                /* no children */
              },
  _definePage_default_17
  )
            ],
          },
  _definePage_default_18
  )
        ],
      }
    ],
  },
  {
    path: '/album',
    /* internal name: '/album' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'creation',
        name: 'AlbumCreation',
        component: () => import('/tmp/website/src/pages/album/creation.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'detail',
        name: 'AlbumDetail',
        component: () => import('/tmp/website/src/pages/album/detail.vue'),
        /* no children */
      },
  _definePage_default_20
  )
    ],
  },
  {
    path: '/auction',
    /* internal name: '/auction' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Auction',
        component: () => import('/tmp/website/src/pages/auction/index.vue'),
        /* no children */
      },
  _definePage_default_21
  )
    ],
  },
  {
    path: '/collection',
    /* internal name: '/collection' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'List',
        name: 'CollectionList',
        component: () => import('/tmp/website/src/pages/collection/List.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
      {
        path: 'nft',
        /* internal name: '/collection/nft' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: '/collection/nft/[id]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'CollectionNFT',
                component: () => import('/tmp/website/src/pages/collection/nft/[id]/index.vue'),
                /* no children */
              },
  _definePage_default_23
  ),
  _mergeRouteRecord(
              {
                path: 'detail',
                name: 'NFTDetail',
                component: () => import('/tmp/website/src/pages/collection/nft/[id]/detail.vue'),
                /* no children */
              },
  _definePage_default_24
  ),
              {
                path: 'on-sale',
                name: '/collection/nft/[id]/on-sale',
                component: () => import('/tmp/website/src/pages/collection/nft/[id]/on-sale.vue'),
                /* no children */
              }
            ],
          },
  _mergeRouteRecord(
          {
            path: 'creation',
            name: 'CollectionNFTCreation',
            component: () => import('/tmp/website/src/pages/collection/nft/creation.vue'),
            /* no children */
          },
  _definePage_default_25
  )
        ],
      }
    ],
  },
  {
    path: '/community',
    /* internal name: '/community' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Community',
        component: () => import('/tmp/website/src/pages/community/index.vue'),
        /* no children */
      },
  _definePage_default_26
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'CommunityHome',
        component: () => import('/tmp/website/src/pages/community/[id].vue'),
        /* no children */
      },
  _definePage_default_27
  ),
  _mergeRouteRecord(
      {
        path: 'agent-list',
        name: 'AgentList',
        component: () => import('/tmp/website/src/pages/community/agent-list.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
  _mergeRouteRecord(
      {
        path: 'artist-list',
        name: 'ArtistList',
        component: () => import('/tmp/website/src/pages/community/artist-list.vue'),
        /* no children */
      },
  _definePage_default_29
  ),
  _mergeRouteRecord(
      {
        path: 'collector-list',
        name: 'CollectorList',
        component: () => import('/tmp/website/src/pages/community/collector-list.vue'),
        /* no children */
      },
  _definePage_default_30
  ),
  _mergeRouteRecord(
      {
        path: 'creation',
        name: 'CommunityCreation',
        component: () => import('/tmp/website/src/pages/community/creation.vue'),
        /* no children */
      },
  _definePage_default_31
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: 'CommunityList',
        component: () => import('/tmp/website/src/pages/community/list.vue'),
        /* no children */
      },
  _definePage_default_32
  ),
      {
        path: 'post',
        /* internal name: '/community/post' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'Post',
            component: () => import('/tmp/website/src/pages/community/post/[id].vue'),
            /* no children */
          },
  _definePage_default_33
  ),
  _mergeRouteRecord(
          {
            path: 'creation',
            name: 'PostCreation',
            component: () => import('/tmp/website/src/pages/community/post/creation.vue'),
            /* no children */
          },
  _definePage_default_34
  )
        ],
      }
    ],
  },
  {
    path: '/event',
    /* internal name: '/event' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'EventHome',
        component: () => import('/tmp/website/src/pages/event/[id].vue'),
        /* no children */
      },
  _definePage_default_35
  ),
  _mergeRouteRecord(
      {
        path: 'creation',
        name: 'EventCreation',
        component: () => import('/tmp/website/src/pages/event/creation.vue'),
        /* no children */
      },
  _definePage_default_36
  )
    ],
  },
  {
    path: '/explore',
    /* internal name: '/explore' */
    /* no component */
    children: [
      {
        path: '',
        name: 'Explore',
        component: () => import('/tmp/website/src/pages/explore/index.vue'),
        /* no children */
      },
      {
        path: 'artists',
        name: 'ExploreArtists',
        component: () => import('/tmp/website/src/pages/explore/artists.vue'),
        /* no children */
      },
      {
        path: 'collectors',
        name: 'ExploreCollectors',
        component: () => import('/tmp/website/src/pages/explore/collectors.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/follow',
    name: 'Follow',
    component: () => import('/tmp/website/src/pages/follow.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'artists',
        name: 'FollowArtists',
        component: () => import('/tmp/website/src/pages/follow/artists.vue'),
        /* no children */
      },
  _definePage_default_37
  ),
  _mergeRouteRecord(
      {
        path: 'collectors',
        name: 'FollowCollectors',
        component: () => import('/tmp/website/src/pages/follow/collectors.vue'),
        /* no children */
      },
  _definePage_default_38
  ),
  _mergeRouteRecord(
      {
        path: 'fans',
        name: 'FollowFans',
        component: () => import('/tmp/website/src/pages/follow/fans.vue'),
        /* no children */
      },
  _definePage_default_39
  )
    ],
  },
  _definePage_default_40
  ),
  {
    path: '/legalize',
    /* internal name: '/legalize' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'agent',
        name: 'LegalizeAgent',
        component: () => import('/tmp/website/src/pages/legalize/agent.vue'),
        /* no children */
      },
  _definePage_default_41
  ),
  _mergeRouteRecord(
      {
        path: 'artist',
        name: 'LegalizeArtist',
        component: () => import('/tmp/website/src/pages/legalize/artist.vue'),
        /* no children */
      },
  _definePage_default_42
  ),
  _mergeRouteRecord(
      {
        path: 'collector',
        name: 'LegalizeCollector',
        component: () => import('/tmp/website/src/pages/legalize/collector.vue'),
        /* no children */
      },
  _definePage_default_43
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/letter-box',
    name: 'LetterBox',
    component: () => import('/tmp/website/src/pages/letter-box.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'ats',
        name: 'LetterBoxAts',
        component: () => import('/tmp/website/src/pages/letter-box/ats.vue'),
        /* no children */
      },
  _definePage_default_44
  ),
  _mergeRouteRecord(
      {
        path: 'comments',
        name: 'LetterBoxComments',
        component: () => import('/tmp/website/src/pages/letter-box/comments.vue'),
        /* no children */
      },
  _definePage_default_45
  ),
  _mergeRouteRecord(
      {
        path: 'likes',
        name: 'LetterBoxLikes',
        component: () => import('/tmp/website/src/pages/letter-box/likes.vue'),
        /* no children */
      },
  _definePage_default_46
  ),
  _mergeRouteRecord(
      {
        path: 'news',
        name: 'LetterBoxNews',
        component: () => import('/tmp/website/src/pages/letter-box/news.vue'),
        /* no children */
      },
  _definePage_default_47
  ),
  _mergeRouteRecord(
      {
        path: 'system',
        name: 'LetterBoxSystem',
        component: () => import('/tmp/website/src/pages/letter-box/system.vue'),
        /* no children */
      },
  _definePage_default_48
  )
    ],
  },
  _definePage_default_49
  ),
  {
    path: '/playground',
    name: '/playground',
    component: () => import('/tmp/website/src/pages/playground.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('/tmp/website/src/pages/profile.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'activity',
        name: 'ProfileActivity',
        component: () => import('/tmp/website/src/pages/profile/activity.vue'),
        /* no children */
      },
  _definePage_default_50
  ),
  _mergeRouteRecord(
      {
        path: 'activity-manage',
        name: 'ProfileActivityManage',
        component: () => import('/tmp/website/src/pages/profile/activity-manage.vue'),
        /* no children */
      },
  _definePage_default_51
  ),
  _mergeRouteRecord(
      {
        path: 'agent',
        name: 'ProfileAgent',
        component: () => import('/tmp/website/src/pages/profile/agent.vue'),
        /* no children */
      },
  _definePage_default_52
  ),
  _mergeRouteRecord(
      {
        path: 'authentication',
        name: 'ProfileAuthentication',
        component: () => import('/tmp/website/src/pages/profile/authentication.vue'),
        /* no children */
      },
  _definePage_default_53
  ),
  _mergeRouteRecord(
      {
        path: 'community',
        name: 'ProfileCommunity',
        component: () => import('/tmp/website/src/pages/profile/community.vue'),
        /* no children */
      },
  _definePage_default_54
  ),
  _mergeRouteRecord(
      {
        path: 'community-manage',
        name: 'ProfileCommunityManage',
        component: () => import('/tmp/website/src/pages/profile/community-manage.vue'),
        /* no children */
      },
  _definePage_default_55
  ),
  _mergeRouteRecord(
      {
        path: 'edit',
        name: 'ProfileEdit',
        component: () => import('/tmp/website/src/pages/profile/edit.vue'),
        /* no children */
      },
  _definePage_default_56
  ),
  _mergeRouteRecord(
      {
        path: 'invitation',
        name: 'ProfileInvitation',
        component: () => import('/tmp/website/src/pages/profile/invitation.vue'),
        /* no children */
      },
  _definePage_default_57
  ),
  _mergeRouteRecord(
      {
        path: 'my-nft',
        name: 'ProfileMyNFT',
        component: () => import('/tmp/website/src/pages/profile/my-nft.vue'),
        /* no children */
      },
  _definePage_default_58
  )
    ],
  },
  _definePage_default_59
  ),
  {
    path: '/search',
    /* internal name: '/search' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Search',
        component: () => import('/tmp/website/src/pages/search/index.vue'),
        /* no children */
      },
  _definePage_default_60
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/support',
    name: 'Support',
    component: () => import('/tmp/website/src/pages/support.vue'),
    /* no children */
  },
  _definePage_default_61
  ),
  {
    path: '/tag',
    /* internal name: '/tag' */
    /* no component */
    children: [
      {
        path: ':name',
        /* internal name: '/tag/[name]' */
        /* no component */
        children: [
          {
            path: 'post',
            /* internal name: '/tag/[name]/post' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'TagPosts',
                component: () => import('/tmp/website/src/pages/tag/[name]/post/index.vue'),
                /* no children */
              },
  _definePage_default_62
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/user-space',
    /* internal name: '/user-space' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'UserSpace',
        component: () => import('/tmp/website/src/pages/user-space/[id].vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'communities',
            name: 'UserSpaceCommunities',
            component: () => import('/tmp/website/src/pages/user-space/[id]/communities.vue'),
            /* no children */
          },
  _definePage_default_63
  ),
  _mergeRouteRecord(
          {
            path: 'events',
            name: 'UserSpaceEvents',
            component: () => import('/tmp/website/src/pages/user-space/[id]/events.vue'),
            /* no children */
          },
  _definePage_default_64
  ),
  _mergeRouteRecord(
          {
            path: 'highlights',
            name: 'UserSpaceHighlights',
            component: () => import('/tmp/website/src/pages/user-space/[id]/highlights.vue'),
            /* no children */
          },
  _definePage_default_65
  ),
  _mergeRouteRecord(
          {
            path: 'nfts',
            name: 'UserSpaceNFTs',
            component: () => import('/tmp/website/src/pages/user-space/[id]/nfts.vue'),
            /* no children */
          },
  _definePage_default_66
  ),
  _mergeRouteRecord(
          {
            path: 'offers',
            name: 'UserSpaceOffers',
            component: () => import('/tmp/website/src/pages/user-space/[id]/offers.vue'),
            /* no children */
          },
  _definePage_default_67
  )
        ],
      },
  _definePage_default_68
  )
    ],
  }
]
