<script setup lang="ts">
import type { UserModel } from '@eggor/vue-shared'
import { WebsiteApi } from '@eggor/vue-shared'

definePage({
  name: 'UserEdit',
})
const route = useRoute<'MyUserProfile'>()
const uid = route.params.id
const infoResult = WebsiteApi.useGetUserInfo(uid, { immediate: false })
onActivated(infoResult.execute)
useOnUserChanged(() => infoResult.execute())

const isEdit = ref<boolean>(false)
</script>

<template>
  <e-remote :result="infoResult">
    <template #default="{ data }">
      <div v-show="!isEdit">
        <profile-edit-show v-model="isEdit" :user="((data.value?.data) as UserModel)" />
      </div>
      <div v-show="isEdit">
        <profile-edit-modify v-model="isEdit" :user="((data.value?.data) as UserModel)" :is-agent="true" />
      </div>
    </template>
  </e-remote>
</template>

<style scoped>

</style>
