<script setup lang="ts">
import type { DataTableColumns } from 'naive-ui'
import { NButton } from 'naive-ui'
import { h } from 'vue'
import * as echarts from 'echarts'
import { useUserStore } from '@eggor/vue-shared'
import Bid from '~/components/auction/Bid.vue'
import Offer from '~/components/auction/Offer.vue'

// NFT详情页
definePage({ name: 'NFTDetail' })
const isLike = ref<boolean>(false)
const isGrounding = ref<boolean>(false)
const isShowDialog = ref<boolean>(false)
const userStore = useUserStore()
const historyPriceChart = ref()
const userId = '1031'
const historyPrice = ref()
const data = ref({
  img: '',
  albumName: '专辑名',
  albumNumber: '专辑编号',
  userName: '艺术家名',
  price: 0.033,
  restPrice: 0,
  floorPrice: 0.0169,
  BestPrice: 0.0151,
})
interface OfferDataModel {
  price: number
  USDPrice: number
  quantity: number
  floor: number
  expiration: string
  from: string
}
interface TradeDataModel {
  type: string
  price: number
  from: string
  to: string
  date: string
}
function receiveHandle(_rowData: OfferDataModel) {

}
const offerColumns = reactive<DataTableColumns<OfferDataModel>>([
  {
    title: 'Price',
    key: 'price',
    render(rowData, _rowIndex) {
      return h(
        'span',
        {},
        { default: () => `${rowData.price}WETH` },
      )
    },
  },
  {
    title: 'USD Price',
    key: 'USDPrice',
    render(rowData, _rowIndex) {
      return h(
        'span',
        {},
        { default: () => `$${rowData.USDPrice}` },
      )
    },
  },
  {
    title: 'Quantity',
    key: 'quantity',
  },
  {
    title: 'Floor Difference',
    key: 'floor',
    render(rowData, _rowIndex) {
      return h(
        'span',
        {},
        { default: () => `${(rowData.floor * 100).toFixed(0)}% below` },
      )
    },
  },
  {
    title: 'Expiration',
    key: 'expiration',
  },
  {
    title: 'From',
    key: 'from',
  },
  {
    title: 'Receive',
    key: 'receive',
    className: isGrounding.value ? '' : 'hidden',
    render(rowData, _rowIndex) {
      return h(NButton, {
        onClick: () => receiveHandle(rowData),
      }, '接受报价')
    },
  },
])
const tradeColumns = reactive<DataTableColumns<TradeDataModel>>([
  {
    title: '',
    key: 'type',
  },
  {
    title: 'Price',
    key: 'price',
    render(rowData, _rowIndex) {
      return h(
        'span',
        {},
        { default: () => `${rowData.price}ETH` },
      )
    },
  },
  {
    title: 'From',
    key: 'from',
  },
  {
    title: 'To',
    key: 'to',
  },
  {
    title: 'Date',
    key: 'date',
  },
])
onMounted(() => {
  historyPriceChart.value = echarts.init(historyPrice.value)
  historyPriceRender()
})
function historyPriceRender() {
  const options = {
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
        },
        data: ['Jul24', 'Aug7', 'Dec12'],
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Volume(ETH)',
        position: 'left',
        nameGap: '25',
        nameLocation: 'middle',
        alignTicks: true,
        max(value: any) {
          return value.max * 2
        },
        axisLine: {
          show: false,
        },
      },
      {
        type: 'value',
        name: 'Average Price(ETH)',
        position: 'right',
        nameGap: '25',
        nameLocation: 'middle',
        alignTicks: true,
        axisLine: {
          show: false,
        },
      },
    ],
    legend: {
      top: '1.5rem',
      bottom: 0,
      containLabel: true,
    },
    series: [{
      name: 'Volume(ETH)',
      type: 'bar',
      itemStyle: {
        color: '#e4e8ea',
      },
      data: [{
        name: 'Jul24',
        value: '2',
      }, {
        name: 'Aug7',
        value: '4',
      }, {
        name: 'Dec12',
        value: '15',
      }],
    }, {
      name: 'Average Price(ETH)',
      type: 'line',
      itemStyle: {
        color: '#5e83d6',
      },
      data: [{
        name: 'Jul24',
        value: '1',
      }, {
        name: 'Aug7',
        value: '4',
      }, {
        name: 'Dec12',
        value: '10',
      }],
    }],
  }
  historyPriceChart.value.setOption(options)
}
const offerData = [{
  price: 3.5624,
  USD: 6634.85,
  floor: 0.18,
  quantity: 1,
  expiration: '3 minutes ago',
  from: 'B40EE8',
}, {
  price: 3.5624,
  USD: 6634.85,
  floor: 0.18,
  quantity: 1,
  expiration: '3 minutes ago',
  from: 'B40EE8',
}, {
  price: 3.5624,
  USD: 6634.85,
  floor: 0.18,
  quantity: 1,
  expiration: '3 minutes ago',
  from: 'B40EE8',
}]
const tradeData = [{
  type: 'transfer',
  price: 0.03995,
  from: '老王',
  to: '张三',
  date: '18h ago',
}, {
  type: 'sale',
  price: 0.03995,
  from: '老王',
  to: '张三',
  date: '18h ago',
}, {
  type: 'list',
  price: 0.03995,
  from: '老王',
  to: '张三',
  date: '18h ago',
}, {
  type: 'create',
  price: 0.03995,
  from: '老王',
  to: '张三',
  date: '18h ago',
}]
function showDialog() {
  isShowDialog.value = true
}
</script>

<template>
  <layouts-wrapper class="pt-2.5rem">
    <div class="grid grid-cols-3 grid-rows-[repeat(3,auto)] gap-x-3.75rem">
      <div class="col-span-3 xl:col-span-1">
        <!-- NFTinfo -->
        <img class="p-5rem pt-0" src="https://static.testnet.eggor.io/eggor/2023/09/05/20230905105042A016.png">
        <div>
          <n-tabs type="segment" animated>
            <n-tab-pane name="work" tab="作品">
              <n-card>
                <n-form label-placement="left" :label-width="100">
                  <n-form-item label="作品详情：">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget
                  </n-form-item>
                  <n-form-item label="作品类型：">
                    NFT/视频/音频/3d模型
                  </n-form-item>
                </n-form>
              </n-card>
            </n-tab-pane>
            <n-tab-pane name="artist" tab="艺术家">
              <n-card>
                <n-form label-placement="left" :label-width="100">
                  <n-form-item>
                    <template #label>
                      <div class="text-center">
                        <n-avatar round src="" class="w-3.75rem h-3.75rem object-cover" />
                        <p class="text-1rem font-bold">
                          nickName
                        </p>
                      </div>
                    </template>
                    这里是艺术家简介xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxs
                  </n-form-item>
                  <n-form-item label="艺术家的话：">
                    xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxs
                  </n-form-item>
                </n-form>
              </n-card>
            </n-tab-pane>
          </n-tabs>
        </div>
        <NButton v-if="userId === userStore.user?.userId" class="w-full mt-1rem" type="primary">
          {{ isGrounding ? '取消挂单' : '挂单' }}
        </NButton>

        <n-divider />
      </div>
      <div class="w-full col-span-3 xl:col-span-2 xl:col-start-2 xl:row-span-3">
        <div>
          <p>所属专辑名</p>
          <div class="row justify-between h-3.75rem lh-3.75rem">
            <span class="text-1.5rem font-bold">专辑名 #作品编号</span>
            <div>
              <NButton size="small" quaternary @click="() => isLike = !isLike">
                <span v-if="!isLike" class="i-ph-heart text-1.75rem" />
                <span v-else class="i-ph-heart-duotone text-1.75rem text-secondary" />
              </NButton>
              <NButton size="small" quaternary>
                <span class="i-ph-warning-circle text-1.75rem" />
              </NButton>
            </div>
          </div>
          <div class="grid grid-cols-2 lh-3.75rem text-1rem">
            <div class="row gap-1.25rem b-r-2">
              <n-avatar src="" round class="mt-0.625rem w-2.5rem h-2.5rem object-cover align-middle" />
              <p>
                nickName
              </p>
            </div>
            <div class="m-x-auto">
              <span>分类： Art</span>
            </div>
          </div>
        </div>
        <div class="mt-1.25rem">
          <!-- price -->
          <div class="b-y-1 text-center h-4rem lh-4rem text-1rem">
            <p>最近交易：<span>{{ new Date() }}</span></p>
          </div>
          <div class="w-full h-10rem p-x-2.5rem lh-2.5rem b-y-1  row justify-between">
            <div class="pt-2.5rem">
              <p>现在价格</p>
              <p class="text-1.875rem font-bold">
                0.0276ETH
              </p>
            </div>
            <NButton class="mt-3.875rem" @click="showDialog">
              {{ userId === userStore.user?.userId ? 'Make Bid' : 'Make Offer' }}
            </NButton>
          </div>
        </div>
        <div class="mt-1rem">
          <n-collapse :default-expanded-names="['1', '2', '3', '4']" display-directive="show">
            <n-collapse-item name="1" title="History price">
              <n-card bordered class="h-23rem w-full">
                <div ref="historyPrice" class="w-full h-full" />
              </n-card>
            </n-collapse-item>
            <n-collapse-item name="2" title="Offers">
              <div>
                <n-data-table
                  :columns="offerColumns"
                  :data="offerData"
                />
              </div>
            </n-collapse-item>
            <n-collapse-item name="3" title="Trade Record">
              <n-data-table
                :columns="tradeColumns"
                :data="tradeData"
              />
            </n-collapse-item>
            <n-collapse-item name="4" title="Details">
              <div class="p-x-0.875rem text-1rem font-500 lh-2.5rem grid grid-cols-[repeat(2,1fr)] text-center b-t-1">
                <span>合约地址</span><span>XXXXX</span>
                <span>代币ID</span><span>109</span>
                <span>区块链</span><span>Ethereum</span>
                <span>代币标准</span><span>ERC721</span>
                <span>Token信息</span><span>URL</span>
                <span>版税</span><span>10%</span>
              </div>
            </n-collapse-item>
          </n-collapse>
        </div>
      </div>
      <div class="xl:row-span-2 col-span-3 xl:col-span-1 xl:col-start-1">
        <div class="flex justify-between">
          <span class="text-1.25rem font-bold">评论</span>
          <NButton>
            评论
          </NButton>
        </div>
        <collection-comment-card />
        <collection-comment-card />
        <collection-comment-card />
        <collection-comment-card />
        <collection-comment-card />
      </div>
    </div>
    <collection-more />
    <component :is="userId === userStore.user?.userId ? Bid : Offer" v-if="isShowDialog" v-model="isShowDialog" :data="data" />
  </layouts-wrapper>
</template>

<style scoped>

</style>
