<script setup lang="tsx">
import { type MenuOption, useThemeVars } from 'naive-ui'
import { RouterLink } from 'vue-router/auto'
import type { RouteNamedMap } from 'vue-router/auto/routes'

definePage({
  name: 'AgentProfile',
  redirect: { name: 'AgentProfileEdit' },
})
const { t } = useI18n()
const theme = useThemeVars()
const route = useRoute()

type MenuOptionType = (MenuOption & { key: keyof RouteNamedMap })[]

const menuOptions = computed<MenuOptionType>(() => [{
  label: () => (<RouterLink to={{ name: 'AgentProfileEdit', query: { noTransition: 'true' } }}>{t('agent.profile.title')}</RouterLink>),
  key: 'AgentProfileEdit',
}, {
  label: () => (<RouterLink to={{ name: 'AgentMyArtistPage', query: { noTransition: 'true' } }}>{t('agent.profile.myArtist.title')}</RouterLink>),
  key: 'AgentMyArtistPage',
}, {
  label: () => (<RouterLink to={{ name: 'AgentMyCollectorPage', query: { noTransition: 'true' } }}>{t('agent.profile.myCollector.title')}</RouterLink>),
  key: 'AgentMyCollectorPage',
}, {
  label: () => (<RouterLink to={{ name: 'AgentCommunityPage', query: { noTransition: 'true' } }}>{t('profile.communityManage.title')}</RouterLink>),
  key: 'AgentCommunityPage',
}, {
  label: () => (<RouterLink to={{ name: 'AgentEventPage', query: { noTransition: 'true' } }}>{t('profile.activityManage.title')}</RouterLink>),
  key: 'AgentEventPage',
}, {
  label: () => (<RouterLink to={{ name: 'AlbumPage', query: { noTransition: 'true' } }}>{t('agent.profile.album.title')}</RouterLink>),
  key: 'AlbumPage',
}])
</script>

<template>
  <n-config-provider
    :theme="theme"
    :theme-overrides="{
      Menu: {
        itemHeight: '5rem',
        itemTextColorActive: 'white',
        itemColorActive: '#191715',
        itemColorHover: '#ebebeb',
        itemColorActiveHover: '#191715',
        itemTextColorActiveHover: 'white',
      },
    }"
  >
    <layouts-wrapper class="profile row gap-1.25rem pt-4.375rem">
      <n-menu
        :value="route.name"
        class="w-16.5625rem border-1 border-#BBB7B6 rounded shrink-0 h-min sticky top-0"
        :options="menuOptions"
      />

      <section>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </section>
    </layouts-wrapper>
  </n-config-provider>
</template>

<style scoped>
.profile section {
  flex-grow: 1;
}
:deep(.n-menu) {
  padding-bottom: 0.625rem;
}
:deep(.n-menu .n-menu-item) {
  margin-top: 0.625rem;
}
:deep(.n-menu-item-content) {
  padding-right: 32px;
  text-align: center;
}
:deep(.n-menu .n-menu-item-content::before) {
  left: 0.625rem;
  right: 0.625rem;
}
:deep(.n-menu .n-menu-item-content.n-menu-item-content--selected) {
  overflow: hidden;
}
:deep(.n-menu .n-menu-item-content.n-menu-item-content--selected::after) {
  content: '';
  border: solid 1.3rem white;
  opacity: 0.1;
  position: absolute;
  top: -3rem;
  right: -2rem;
  border-radius: 100%;
  width: 6.5rem;
  height: 6.5rem;
}
</style>
