<script setup lang="ts">
definePage({
  name: 'ProfileCommunity',
})
</script>

<template>
  <profile-community />
</template>

<style scoped></style>
