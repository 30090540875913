<script setup lang="ts">
const props = defineProps({
  name: {
    required: true,
    type: String,
  },
  color: {
    required: false,
    type: String,
  },
  size: {
    required: false,
    type: [String, Number],
  },
})
const { name } = toRefs(props)
const svg = shallowRef<any>()

watchPostEffect(() => {
  const _ = name.value
  if (!_)
    return
  svg.value = defineAsyncComponent(() => import(`../../assets/icons/svgs/${_}.svg?component`))
})
// const svgRef = ref()
const colorInDark = computed(() => props.color ?? 'white')
const colorInLight = computed(() => props.color ?? 'black')
const size = computed(() => typeof props.size === 'number' ? `${props.size}px` : props.size ?? '1em')
</script>

<template>
  <component :is="svg" v-if="svg" class="_icon" />
  <span v-else />
</template>

<style scoped lang="scss">
._icon {
  fill: v-bind(colorInLight);
  display: inline-block;
  width: v-bind(size);
  height: v-bind(size);
  vertical-align: middle;
}
html.dark ._icon {
  fill: v-bind(colorInDark);
}
</style>
