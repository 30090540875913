<script setup lang="ts">
definePage({
  name: 'UserSpaceEvents',
  meta: {
    ignoreScrollToTop: 1,
  },
})
// const { t } = useI18n()
// const list = [[
//   {
//     backgroundImage: '/images/Events1.png',
//     avatar: '/images/Events1.png',
//     name: 'Yuki Tanaka',
//     message: '3D Digital Odyssey',
//     date: 'August 25th, 3:00 PM - 5:00 PM (GMT)',
//   },
//   {
//     backgroundImage: '/images/Events2.png',
//     avatar: '/images/Events2.png',
//     name: 'Carlos Martinez',
//     message: 'Dream of Digital in Modern Painting',
//     date: 'August 25th, 9:00 PM - 12:00 PM (GMT)',
//   },
//   {
//     backgroundImage: '/images/Events3.png',
//     avatar: '/images/Events3.png',
//     name: 'Aisha Khan',
//     message: 'Cultural Canva PK Digital Art Fest',
//     date: 'August 25th, 2:00 PM - 4:00 PM (GMT)',
//   },
// ],
// [
//   {
//     backgroundImage: '/images/Events7.png',
//     avatar: '/images/Events7.png',
//     name: 'Sofia Rodriguez',
//     message: 'Mastering Traditional Crafts',
//     date: 'February 12th, 11:00 AM - 1:00 PM (PST)',
//   },
//   {
//     backgroundImage: '/images/Events8.png',
//     avatar: '/images/Events8.png',
//     name: 'Emily Green',
//     message: 'Sustainable Solutions',
//     date: 'March 22nd, 9:00 AM - 11:00 AM (CST)',
//   },
//   {
//     backgroundImage: '/images/Events9.png',
//     avatar: '/images/Events9.png',
//     name: 'Andrei Petrov',
//     message: 'Cultural Collaborations',
//     date: 'April 7th, 5:30 PM - 7:30 PM (GMT)',
//   },
// ],
// [
//   {
//     backgroundImage: '/images/Events7.png',
//     avatar: '/images/Events7.png',
//     name: 'Yuki Tanaka',
//     message: '3D Digital Odyssey',
//     date: 'August 25th, 3:00 PM - 5:00 PM (GMT)',
//   },
//   {
//     backgroundImage: '/images/Events8.png',
//     avatar: '/images/Events8.png',
//     name: 'Carlos Martinez',
//     message: 'Dream of Digital in Modern Painting',
//     date: 'August 25th, 3:00 PM - 5:00 PM (GMT)',
//   },
//   {
//     backgroundImage: '/images/Events9.png',
//     avatar: '/images/Events9.png',
//     name: 'Aisha Khan',
//     message: 'Cultural Canva PK Digital Art Fest',
//     date: 'August 25th, 3:00 PM - 5:00 PM (GMT)',
//   },
// ],
// ]
</script>

<template>
  <div class="mt-1.875rem">
    <!-- <user-space-event :title="t('profile.activity.card.ongoing')" :data="list[0]" />
    <user-space-event :title="t('profile.activity.card.coming')" :data="list[1]" />
    <user-space-event :title="t('profile.activity.card.history')" :data="list[2]" /> -->
    <n-empty />
  </div>
</template>

<style scoped>

</style>
