import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto/routes'
import { setupLayouts } from 'virtual:generated-layouts'
import type { UserModel } from '@eggor/vue-shared'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: setupLayouts(routes),
  scrollBehavior(to, from, savedPos) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPos) {
      return savedPos
    }
    else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from) => {
  if (to.matched[0].path === '/profile') {
    if (!useLocalStorage('user_info', null).value) {
      if (from.fullPath === '/') {
        return '/'
      }
      return false
    }
  }

  const userModel = useLocalStorage<UserModel | null>('user_info', null, {
    serializer: {
      read: (v: any) => (v ? JSON.parse(v) : null),
      write: (v: any) => JSON.stringify(v),
    },
  })

  if (to.path.match(/\/agent\/(profile|user-profile)/)) {
    if (!(userModel?.value && userModel.value.dept.deptId === 204)) {
      if (from.fullPath === '/') {
        return '/'
      }
      return false
    }
  }
  return true
})

export default router
