<script setup lang="ts">
import { h } from 'vue'
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'
import { I18nT } from 'vue-i18n'

definePage({
  name: 'ProfileInvitation',
})
const message = useMessage()
const { id } = storeToRefs(useUserStore())
const { base64 } = useBase64(computed(() => id.value ?? ''))
const invitationCode = computed(() => base64.value.replace('data:text/plain;base64,', ''))
const { t } = useI18n()

const { paging } = usePaging()
const invitedList = WebsiteApi.useInvitedUsers(paging)

// 复制邀请码
function onCopyCode() {
  // navigator.clipboard is undefined in http
  if (!navigator.clipboard) {
    return message.error(t('profile.invitation.copyFailed'))
  }
  const url = new URL(window.location.origin)
  url.searchParams.append('invitation', invitationCode.value)
  navigator.clipboard.writeText(url.toString()).then(
    () => {
      message.info('', {
        showIcon: false,
        render() {
          return h('div', { class: 'bg-[rgb(0,0,0,.8)] color-white px-10 py-2 rounded-2' }, t('profile.invitation.copySuccess'))
        },
      })
    },
  ).catch(() => {
    message.error(t('profile.invitation.copyFailed'))
  })
}
</script>

<template>
  <div class="col gap-1.25rem">
    <p class="lh-1.25rem text-1rem">
      {{ t('profile.invitation.title') }}
    </p>
    <hr>

    <section class="font-bold text-1rem inline-row">
      {{ t('profile.invitation.codeInvitation') }}

      <n-tag :bordered="false" type="info" class="mx-1rem">
        {{ invitationCode }}
      </n-tag>

      <n-button size="small" @click="onCopyCode">
        <span class="cursor-pointer i-carbon-copy mr-0.5rem" />
        {{ t('profile.invitation.copyLink') }}
      </n-button>
    </section>
    <hr>

    <section>
      <div class="mb-5 font-bold text-1rem">
        {{ t('profile.invitation.recordInvitation') }}
      </div>

      <e-remote-paging
        v-model:paging="paging"
        :result="invitedList"
      >
        <template #default="{ rows }">
          <ul class="text-sm text-neutral-500 col gap-5 text-0.875rem">
            <li v-for="item in rows" :key="item.id" class="inline-row gap-1.5rem">
              <n-time :time="new Date(item.createTime)" />
              <p>
                <I18nT keypath="profile.invitation.record">
                  <template #identity>
                    {{ parseMultilingual(item.invitedUser.dept.deptName) }}
                  </template>
                  <template #userName>
                    <router-link
                      :to="{ name: 'UserSpace', params: { id: item.invitedUser.userId } }"
                      class="font-bold link"
                    >
                      {{ item.invitedUser.nickName }}
                    </router-link>
                  </template>
                  <template #experience>
                    {{ item.experience }}
                  </template>
                </I18nT>
              </p>
            </li>
          </ul>
        </template>
      </e-remote-paging>
    </section>
    <hr>
  </div>
</template>

<style scoped>

</style>
