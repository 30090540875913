<script setup lang="ts">
import { type CommunityDto, WebsiteApi } from '@eggor/vue-shared'

definePage({ name: 'AgentCommunityPage' })
// const showAddCommunityModal = ref(false)
const { paging } = usePaging({ pageSize: 8, pageNum: 1 })
const communitiesResult = WebsiteApi.useAgentCommunities(paging, { refetch: true })
const { t } = useI18n()
const clickCommunity = ref<CommunityDto>()
const currentTab = ref<'first' | 'second'>('first')
</script>

<template>
  <div class="text-xl  row justify-between w-full mb-5 items-center">
    <span>{{ t('agent.profile.communityManage.title') }}</span>
  </div>
  <hr>
  <n-tabs :tab-style="{ display: 'none' }" :value="currentTab" animated>
    <n-tab-pane name="first">
      <e-remote-paging :result="communitiesResult" :paging="paging" class="mt-5">
        <template #default="{ rows }">
          <div class="grid grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] gap-1.25rem ">
            <div v-for="c, i in rows" :key="i" class="col w-full cursor-pointer relative community-container" @click="currentTab = 'second';clickCommunity = c;">
              <img loading="lazy" :src="c.avatar" alt="" class="object-cover w-full h-15.625rem rounded-1rem container-box">
              <div class=" lh-1.25rem mt-1.25rem text-center">
                {{ c.name }}
              </div>
            </div>
          </div>
        </template>
      </e-remote-paging>
    </n-tab-pane>
    <n-tab-pane name="second" class="relative">
      <div class="absolute w-fit right-0 top-15px cursor-pointer z-10">
        <span class="text-2xl i-icon-park-outline-return text-gray " @click="currentTab = 'first'" />
      </div>
      <n-tabs
        type="bar"
        :pane-style="{ marginTop: '1.25rem' }"
      >
        <n-tab-pane name="BasicInfo" :tab="t('profile.communityManage.basicInfo')">
          <profile-community-management-basic-info
            :community="(clickCommunity as CommunityDto)"
            :user-id="clickCommunity?.user.userId"
            @updated="() => {}"
          />
        </n-tab-pane>
        <n-tab-pane name="Permissions" :tab="t('profile.communityManage.permissions.title')">
          <profile-community-management-permissions :community="(clickCommunity as CommunityDto)" :user-id="clickCommunity?.user.userId" />
        </n-tab-pane>
        <n-tab-pane name="Fans" :tab="t('profile.communityManage.fans.title')">
          <profile-community-management-fans :community="(clickCommunity as CommunityDto)" :user-id="clickCommunity?.user.userId" />
        </n-tab-pane>
        <n-tab-pane name="applications" :tab="t('profile.communityManage.applications.title')">
          <profile-community-management-applications :community="(clickCommunity as CommunityDto)" :user-id="clickCommunity?.user.userId" />
        </n-tab-pane>
        <n-tab-pane name="Posts" :tab="t('profile.communityManage.posts.title')">
          <profile-community-management-posts :id="(clickCommunity as CommunityDto).id" />
        </n-tab-pane>
      </n-tabs>
    </n-tab-pane>
  </n-tabs>
</template>

<style scoped>
.community-list:not(:last-child) {
  margin-bottom: 20px;
}

.container-box:hover {
    z-index: 2;
    box-shadow: 0 15px 30px rgba(0,0,0,.1);
    transform: translate3d(0,-2px,0);
}

.container-box {
  pointer-events: auto;
  transition: all 0.2s linear;
}
</style>
