<script setup lang="ts">
import type { AvailableLocale } from '@eggor/vue-shared'
import { useAppStore } from '@eggor/vue-shared'
import type { GlobalThemeOverrides } from 'naive-ui'
import { NGlobalStyle, darkTheme, dateEnUS, dateJaJP, dateKoKR, dateZhCN, enUS, jaJP, koKR, lightTheme, zhCN } from 'naive-ui'
import App from '~/App.vue'

const { isDark, locale } = storeToRefs(useAppStore())

const localeMap: Record<AvailableLocale, [any, any]> = {
  'zh-CN': [zhCN, dateZhCN],
  'en-US': [enUS, dateEnUS],
  'ja-JP': [jaJP, dateJaJP],
  'ko-KR': [koKR, dateKoKR],
}

const naiveLocale = computed(() => {
  try {
    return localeMap[locale.value][0]
  }
  catch {
    locale.value = 'en-US'
    return localeMap['en-US'][0]
  }
})
const naiveDateLocale = computed(() => {
  try {
    return localeMap[locale.value][1]
  }
  catch {
    locale.value = 'en-US'
    return localeMap['en-US'][1]
  }
})
const theme = computed(() => isDark.value ? darkTheme : lightTheme)
const themeOverrides = reactive<GlobalThemeOverrides>({
  common: {
    primaryColor: '#191715',
    primaryColorHover: 'rgba(25, 23, 21, 0.9)',
    primaryColorPressed: 'rgba(25, 23, 21, 0.67)',
    primaryColorSuppl: 'rgba(25, 23, 21, 0.9)',
    borderRadius: '0.625rem',
    borderRadiusSmall: '0.625rem',
    borderColor: '#DFDDDB',
    fontSize: '.875rem',
    fontSizeTiny: '0.75rem',
    fontSizeMini: '0.8125rem',
    fontSizeSmall: '0.875rem',
    fontSizeMedium: '1rem',
    fontSizeLarge: '1.125rem',
    fontSizeHuge: '1.75rem',
  },
  Pagination: {
    itemBorderRadius: '0.25rem',
  },
  Button: {
    borderRadiusLarge: '0.625rem',
    borderRadiusMedium: '0.625rem',
    borderRadiusSmall: '0.625rem',
    borderRadiusTiny: '0.625rem',
    border: '0.0037rem solid #191715',
    colorTertiary: 'white',
    colorTertiaryHover: '#dfdfdf',
    colorTertiaryPressed: 'white',
    colorSecondary: '#dfdddb4d',
    colorSecondaryHover: '#dfdddbad',
    colorSecondaryPressed: '#dfdddb00',
    fontSizeLarge: '1rem',
    paddingLarge: '0.625rem 1.875rem',
  },
  Select: {
    peers: {
      InternalSelection: {
        fontSizeLarge: '1rem',
        border: '0.0037rem solid #191715',
        arrowColor: '#191715',
      },
    },
  },
  Input: {
    color: '#EDEDED',
    border: 'none',
  },
  DataTable: {
    thColor: 'transparent',
    borderRadius: '0.9375rem',
  },
  Card: {
    paddingSmall: '0.625rem',
    paddingMedium: '1.25rem',
    actionColor: 'transparent',
    borderRadius: '0.9375rem',
  },
})
</script>

<template>
  <n-config-provider
    :theme="theme"
    :locale="naiveLocale ?? enUS"
    :date-locale="naiveDateLocale ?? dateEnUS"
    :theme-overrides="themeOverrides"
    :breakpoints="{
      'xs': 0,
      'sm': 640,
      'md': 768,
      'lg': 1024,
      'xl': 1280,
      '2xl': 1536,
    }"
  >
    <NGlobalStyle />

    <n-message-provider>
      <n-notification-provider>
        <n-loading-bar-provider>
          <n-dialog-provider>
            <App />
          </n-dialog-provider>
        </n-loading-bar-provider>
      </n-notification-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<style scoped>

</style>
