<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import type { EffectScope } from 'vue'

const noticeStore = useNoticeStore()
definePage({
  name: 'LetterBoxNews',
})
const notices = reactive([{
  title: '主题',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.',
}, {
  title: '主题',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.',
}, {
  title: '主题',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.',
}, {
  title: '主题',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.',
}, {
  title: '主题',
  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.',
}])
const { paging } = usePaging({ pageSize: 5, pageNum: 1 })
const result = WebsiteApi.useNotificationSystemMessage(paging, { refetch: false, immediate: false })
let dirty = false
const { noticeUnreadCount } = useWebsiteAppStore()
watch(() => noticeUnreadCount.news, () => dirty = true)

let scope: EffectScope

onActivated(() => {
  noticeStore.pagingNum = paging.value.pageNum!

  if (dirty) {
    result.execute()
    dirty = false
  }
  scope = effectScope()
  scope?.run(() => {
    watch(noticeStore, () => {
      paging.value.pageNum = noticeStore.pagingNum
    })
  })
})
onMounted(() => {
  noticeStore.pagingNum = paging.value.pageNum!
})
watch(paging, value => noticeStore.pagingNum = value.pageNum!)

onDeactivated(() => {
  scope?.stop()
})
</script>

<template>
  <e-remote :result="result">
    <template #default>
      <div
        v-for="notice, index in notices"
        :key="index"
        class="mb-0.94rem py-3.12rem px-4.38rem shadow-sm bg-[rgba(255,255,255,0.85)] rounded"
      >
        <h3 class="font-bold">
          {{ parseMultilingual(notice.title) }}
        </h3>
        <p class="mt-1.25rem">
          {{ parseMultilingual(notice.content) }}
        </p>
      </div>
    </template>
  </e-remote>
</template>

<style scoped>

</style>
