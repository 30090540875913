<script setup lang="ts">
definePage({ name: 'UserAlbumPage' })
const route = useRoute<'MyUserProfile'>()
const uid = route.params.id
</script>

<template>
  <profile-album :uid="uid" />
</template>

<style scoped>

</style>
