<script setup lang="ts">
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'

definePage({
  name: 'TagPosts',
})
const name = useRoute('TagPosts').params.name
if (!name)
  useRouter().replace('/')
const userStore = useUserStore()
const { paging } = usePaging({ pageSize: 20, pageNum: 1 })
const postsResult = WebsiteApi.usePostsByTag(name, paging)
const userCommunityResult = WebsiteApi.useCommunityByUserId(computed(() => userStore.user?.userId))
const adminResult = WebsiteApi.useSelectCommunityAdmin()
useAdaptiveRefetch(postsResult)
</script>

<template>
  <layouts-wrapper>
    <e-remote-paging
      v-model:paging="paging"
      :result="postsResult"
    >
      <template #default="{ rows }">
        <community-home-post-item
          v-for="post, index in rows"
          :key="post.id"
          :post="post"
          :is-my-community="post.communityId === userCommunityResult.data.value?.data?.id"
          :is-community-admin="adminResult.data.value?.rows.some(row => row.communityId === Number(post.communityId))"
          class="pt-1.875rem pb-1.5625rem max-w-60rem"
          :class="index ? ['border-t-1px'] : []"
          @fresh-post="postsResult.execute(false)"
        />
      </template>
    </e-remote-paging>
  </layouts-wrapper>
</template>

<style scoped>

</style>
