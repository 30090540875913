<script setup lang="ts">
definePage({ name: 'AgentMyArtistPage' })
</script>

<template>
  <agent-profile-user type="artist" />
</template>

<style scoped>

</style>
