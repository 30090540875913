<script setup lang="ts">
// import { useUserStore } from '@eggor/vue-shared'

const { t } = useI18n()
const bannerIndex = ref(0)
const banners = reactive([t('roles.artist'), t('roles.collector')])

definePage({
  name: 'Community',
})

// const userStore = useUserStore()
</script>

<template>
  <layouts-wrapper>
    <n-layout class="pt-2.5rem" has-sider sider-placement="right" :content-style="{ gap: '6.25rem' }">
      <n-layout-content :content-style="{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }">
        <div class="row gap-1.875rem">
          <n-button
            v-for="banner, index in banners"
            :key="index" size="large"
            :class="{ active: index === bannerIndex }"
            :name="banner"
            class="banner-switcher !font-bold"
            :secondary="index === bannerIndex"
            :tertiary="index !== bannerIndex"
            @click="bannerIndex = index"
          >
            {{ banner }}
          </n-button>
        </div>

        <community-banner v-model:current-index="bannerIndex" class="mt-1.875rem" :show-dots="false" :touchable="false" />
      </n-layout-content>

      <n-layout-sider class="<xl:hidden mt-4.3125rem" width="22.375rem">
        <community-sider />
      </n-layout-sider>
    </n-layout>
  </layouts-wrapper>
</template>

<style scoped lang="scss">
.banner-switcher.active {
  background-image: url("/images/temp_community_banner.png") !important;
  background-position: 0;
  background-size: cover;
  background-color: transparent !important;
  color: transparent;
  &::after {
    color: white;
    margin: auto;
    content: attr(name);
    position: absolute;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    backdrop-filter: blur(.25rem);
    border-radius: 0.625rem;
  }
}
</style>
