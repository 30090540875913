<script setup lang="ts">
definePage({
  name: 'ProfileMyNFT',
})
</script>

<template>
  <profile-album />
</template>

<style scoped>
</style>
