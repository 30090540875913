<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import type { RouteNamedMap } from 'vue-router/auto/routes'

definePage({
  name: 'AgentUserSpace',
  redirect: { name: 'AgentInfo' },
})
const { t } = useI18n()
const route = useRoute<'AgentUserSpace'>()
const uid = route.params.id
const tabs = computed(() => ({
  AgentInfo: t('agent.user-space.AgentInfo'),
  CollaboratingArtists: t('agent.user-space.CollaboratingArtists'),
  CollaboratingCollectors: t('agent.user-space.CollaboratingCollectors'),
}) as Record<keyof RouteNamedMap, string>)
const userInfoResult = WebsiteApi.useAgentInfo(uid)
useAdaptiveRefetch(userInfoResult)
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <n-layout>
      <!-- 头部 -->
      <n-layout-header>
        <e-remote :result="userInfoResult">
          <template #default="{ data }">
            <agent-user-space-header
              :user="data.value!.data!"
            />
          </template>
        </e-remote>
      </n-layout-header>
    </n-layout>
    <n-layout-content>
      <div class="row gap-1.25rem mt-4.375rem">
        <router-link
          v-for="(item, index) in Object.keys(tabs)"
          v-once
          :key="index"
          :to="{ name: item as any, params: { id: route.params.id } }"
        >
          <n-button
            :tertiary="route.name !== item"
          >
            {{ tabs[item as keyof RouteNamedMap] }}
          </n-button>
        </router-link>
      </div>

      <hr class="mt-1.25rem">

      <!-- 子tab -->
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </n-layout-content>
  </layouts-wrapper>
</template>
