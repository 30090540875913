<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({ name: 'AlbumDetail' })
const route = useRoute()

const albumDetailResult = WebsiteApi.useGetAlbumDetail(computed(() => Number(route.query.id)), { refetch: () => Boolean(route.query.id) })
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <e-remote :result="albumDetailResult">
      <template #default="{ data }">
        <album-detail-header :data="data.value?.data!" />
        <album-detail-items :data="data" />
      </template>
    </e-remote>
  </layouts-wrapper>
</template>

<style scoped>

</style>
