<script setup lang="ts">
import { ContentWidth, IndexCoverHeight, MaxContentWidth } from '~/constants'

const { t } = useI18n()
definePage({
  name: 'Home',
  meta: {
    extendBodyBehindHeader: true,
  },
})
</script>

<template>
  <div class="cover" :style="{ height: IndexCoverHeight }" />

  <div class="m-auto col gap-4.38rem" :style="{ maxWidth: MaxContentWidth, width: ContentWidth }">
    <home-banner class="mt-8.625rem h-34.375rem" />

    <e-section title="index.communities.title">
      <home-hot-communities class="m-auto max-w-67.125rem h-67.125rem" />
    </e-section>

    <!-- <e-section title="index.activities.title" more>
      <home-activities />
    </e-section> -->

    <!-- <e-section title="index.verifiedAgent.title" more :to="{ name: 'AgentList' }">
      <home-verified-agent />
    </e-section> -->
    <section>
      <div class="row">
        <h2 class="text-1.625rem flex-grow-1">
          {{ t("index.verifiedAgent.title") }}
        </h2>
      </div>

      <div class="mt-3.125rem">
        <home-verified-agent />
      </div>
    </section>
    <!--
    <e-section title="index.marketplace.title">
      <home-marketplace />
    </e-section> -->

    <e-section title="index.gallery.title">
      <home-gallery />
    </e-section>
  </div>
</template>

<style lang="scss" scoped>
.cover {
  background-image: url('/images/index_cover.png');
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 100% 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}
</style>
