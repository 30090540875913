<script setup lang="ts">
definePage({
  name: 'CollectionNFT',
})
</script>

<template>
  <div />
</template>

<style scoped>

</style>
