<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import type { RouteNamedMap } from 'vue-router/auto/routes'
import { ArtistDeptId, CollectorDeptId } from '~/constants'

definePage({
  name: 'UserSpace',
})
const router = useRouter()
const route = useRoute<'UserSpace'>()

const { t } = useI18n()

const tabs = computed(() => ({
  // UserSpaceNFTs: t('user-space.NFTs'),
  UserSpaceHighlights: t('user-space.highlights.title'),
  UserSpaceEvents: t('user-space.events'),
  UserSpaceCommunities: t('user-space.communities'),
  // UserSpaceOffers: t('user-space.offers'),
}) as Record<keyof RouteNamedMap, string>)

// 带参页面不要响应式！
const uid = route.params.id
const userInfoResult = WebsiteApi.useUserInfo(uid)

tryOnScopeDispose(router.afterEach((to, _from, _next) => {
  if (to.name === 'UserSpace')
    return router.replace({ ...to, name: 'UserSpaceNFTs' })
}))
onMounted(() => {
  if (route.name === 'UserSpace')
    router.replace({ name: 'UserSpaceNFTs' })
})
useAdaptiveRefetch(userInfoResult)
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <n-layout>
      <!-- 头部 -->
      <n-layout-header>
        <e-remote :result="userInfoResult">
          <template #default="{ data }">
            <user-space-header
              v-if="[ArtistDeptId, CollectorDeptId].includes(Number(data.value?.data?.dept.deptId) as 202)"
              :user="data.value!.data!"
            />
            <user-space-ordinary-header
              v-else
              :user="data.value!.data!"
            />
          </template>
        </e-remote>
      </n-layout-header>

      <!-- tab区 -->
      <n-layout-content>
        <div class="row gap-1.25rem mt-4.375rem">
          <router-link
            v-for="(item, index) in Object.keys(tabs)"
            v-once
            :key="index"
            :to="{ name: item as any, params: { id: route.params.id } }"
          >
            <n-button
              :tertiary="route.name !== item"
            >
              {{ tabs[item as keyof RouteNamedMap] }}
            </n-button>
          </router-link>
        </div>

        <hr class="mt-1.25rem">

        <!-- 子tab -->
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </n-layout-content>
    </n-layout>
  </layouts-wrapper>
</template>
