<script setup lang="ts">
definePage({
  name: 'Auction',
})
</script>

<template>
  <layouts-wrapper class="pt-2.5rem col gap-4.38rem items-stretch">
    <section>
      <n-carousel class="mt-1.875rem h-16.375rem rounded" :show-dots="true" :touchable="false" :space-between="24" :autoplay="true">
        <n-carousel-item>
          <n-skeleton class="h-full" />
        </n-carousel-item>
        <n-carousel-item>
          <n-skeleton class="h-full" />
        </n-carousel-item>
      </n-carousel>
    </section>

    <section>
      <div class="row">
        <h2 class="text-1.625rem flex-grow-1">
          Live auction
        </h2>
      </div>

      <div class="mt-3.125rem">
        <auction-row filter="live" />
      </div>
    </section>

    <section>
      <div class="row">
        <h2 class="text-1.625rem flex-grow-1">
          New auction
        </h2>
      </div>

      <div class="mt-3.125rem">
        <auction-row filter="live" />
      </div>
    </section>

    <section>
      <div class="row">
        <h2 class="text-1.625rem flex-grow-1">
          Hot auction
        </h2>
      </div>

      <div class="mt-3.125rem">
        <auction-row filter="live" />
      </div>
    </section>

    <section>
      <div class="row">
        <h2 class="text-1.625rem flex-grow-1">
          Recommended auction
        </h2>
      </div>

      <div class="mt-3.125rem">
        <auction-row filter="live" />
      </div>
    </section>
  </layouts-wrapper>
</template>

<style scoped>

</style>
