<script setup lang="ts">
definePage({
  name: 'AgentList',
})
</script>

<template>
  <layouts-default-wrapper>
    Working on it...
  </layouts-default-wrapper>
</template>

<style scoped>

</style>
