<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'

definePage({ name: 'AgentEventPage' })

const { t } = useI18n()
const activityType = ref<number>()
const { data: ongoing } = WebsiteApi.useAgentActivities({ refetch: true }, { pageSize: 6, pageNum: 1 }, computed(() => ({ type: activityType.value, status: 1 })))
const { data: coming } = WebsiteApi.useAgentActivities({ refetch: true }, { pageSize: 6, pageNum: 1 }, computed(() => ({ type: activityType.value, status: 2 })))
const { data: history } = WebsiteApi.useAgentActivities({ refetch: true }, { pageSize: 6, pageNum: 1 }, computed(() => ({ type: activityType.value, status: 3 })))
</script>

<template>
  <div class="mb-1.25rem row justify-between">
    <p class="text-1rem">
      {{ t('profile.activityManage.title') }}
    </p>
    <n-select
      v-model:value="activityType" :options="[
        {
          label: t('profile.activityManage.create'),
          value: 1,
        },
        {
          label: t('profile.activityManage.join'),
          value: 2,
        },
      ]" style="max-width: 8.5rem;"
    />
  </div>
  <hr>
  <div class="mb-3.125rem mt-1.25rem">
    <profile-event-card :title="t('profile.activity.card.ongoing')" :activity="ongoing?.rows ?? []" />
    <profile-event-card :title="t('profile.activity.card.coming')" :activity="coming?.rows ?? []" />
    <profile-event-card :title="t('profile.activity.card.history')" :activity="history?.rows ?? []" />
  </div>
</template>

<style scoped>

</style>
