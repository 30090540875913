import type { EventBusKey } from '@vueuse/core'

export const NavHeaderIndex = 100
export const NavHeaderHeight = '4.25rem'
export const ContentWidth = '88%'
export const MaxContentWidth = '99.375rem'
export const ContentPadding = '0'
export const IndexCoverHeight = '38.625rem'
export const PageTransitionDuration = '320ms'

export const NormalDeptId: number = 201
export const ArtistDeptId: number = 202
export const CollectorDeptId: number = 203
export const AgentDeptId: number = 204

export const TopLevelRouteBypassNames = ['LetterBox', 'Profile', 'UserSpace', 'AgentProfile', 'MyUserProfile', 'AgentUserSpace']

/**
 * Event bus keys
 */

export const EventPostPublished: EventBusKey<{ postId: string }> = Symbol('post-published')
