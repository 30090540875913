<script setup lang="ts">
import type { FormInst, FormRules, UploadFileInfo } from 'naive-ui'
import { WebsiteApi, useUserStore } from '@eggor/vue-shared'

import type { SelectMixedOption } from 'naive-ui/es/select/src/interface'

definePage({
  name: 'AlbumCreation',
})
const userStore = useUserStore()
const coverImage = ref<UploadFileInfo[]>([])
const backgroundImage = ref<UploadFileInfo[]>([])
const chainMap = new Map([['Ethereum', 1]])
const chainOptions = ref<SelectMixedOption[]>([{
  label: 'Ethereum',
  value: 'Ethereum',
}])

const dialogForm = ref({
  userId: Number(userStore.user!.userId),
  cover: '',
  title: '',
  blockchainAddress: '',
  chain: '',
  chainId: '',
  type: '',
  creatorDivision: '',
  description: '',
  backgroundUrl: '',
})
watch(() => dialogForm.value.chain, (newValue) => {
  if (chainMap.get(newValue)) {
    dialogForm.value.chainId = String(chainMap.get(newValue))
  }
})

const { execute, data, isFetching } = WebsiteApi.useCreateAlbum(dialogForm, { immediate: false })

const formRef = ref<FormInst | null>(null)
const rules: FormRules = {

}
function submit() {
  execute()
}
const message = useMessage()
const router = useRouter()
function resetStates() {
  dialogForm.value.title = ''
  dialogForm.value.blockchainAddress = ''
  dialogForm.value.chain = ''
  dialogForm.value.chainId = ''
  dialogForm.value.type = ''
  dialogForm.value.description = ''
  dialogForm.value.creatorDivision = ''

  coverImage.value.length = 0
  backgroundImage.value.length = 0
}
watch(data, (newValue) => {
  if (newValue && newValue.code !== 500) {
    message.success('创建专辑成功')
    resetStates()
    router.push({ name: 'AlbumPage' })
  }
})
watch(coverImage, (newValue) => {
  if (newValue?.[0]?.status === 'finished') {
    return dialogForm.value.cover = newValue[0].url!
  }
  return dialogForm.value.cover = ''
})
watch(backgroundImage, (newValue) => {
  if (newValue?.[0]?.status === 'finished') {
    return dialogForm.value.backgroundUrl = newValue[0].url!
  }
  return dialogForm.value.backgroundUrl = ''
})
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <p class="font-bold text-1.5rem">
      创建一个新NFT
    </p>
    <pre>
      <!-- {{ dialogForm }} -->
    </pre>
    <!-- {{ previewFileList }} -->
    <n-form ref="formRef" :rules="rules" :model="dialogForm">
      <n-form-item>
        <e-upload v-model:file-list="coverImage" width="300px">
          <div>
            <n-button class="w-15rem h-2.5rem m-b-2.5rem rd-1.25rem">
              上传作品
            </n-button>
            <p class="p-x-2.5rem">
              支持格式：JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF
              <br>
              最大大小：100mb
            </p>
          </div>
        </e-upload>
      </n-form-item>

      <n-form-item>
        <div>
          <div class="font-bold text-1.25rem mb-0.75rem">
            背景图片
          </div>
          <e-upload v-model:file-list="backgroundImage" width="300px" />
        </div>
      </n-form-item>
      <!-- <n-form-item>
        <div>
          <n-upload
            v-model:file-list="previewFileList"
            action="/admin-api/eggor/uploadFile/uploadMinio"
            list-type="image-card"
            accept="image/png, image/jpeg"
            :headers="headers"
            @finish="finishHandle"
          >
            <n-upload-dragger>
              <div>
                <n-button class="w-15rem h-2.5rem m-b-2.5rem rd-1.25rem">
                  上传作品
                </n-button>
                <p class="p-x-2.5rem">
                  支持格式：JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF
                  <br>
                  最大大小：100mb
                </p>
              </div>
            </n-upload-dragger>
          </n-upload>
        </div>
      </n-form-item> -->
      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            Name
          </p>
          <n-input v-model:value="dialogForm.title" size="medium" style="min-width:50rem" />
        </div>
      </n-form-item>
      <!-- <n-form-item>
        <div class="col gap-1.25rem">
          <p class="font-bold text-1.25rem">
            外部链接
          </p>
          <p>
            将在这个项目的详细信息页面上包含一个指向这个的链接，以便用户可以点击了解更多信息。欢迎你连结至你自己的网页，提供更多资料。
          </p>
          <n-input size="medium" style="width: 25rem;" />
        </div>
      </n-form-item> -->
      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            详情
          </p>
          <n-input v-model:value="dialogForm.description" type="textarea" size="medium" style="min-width:50rem" :resizable="false" />
        </div>
      </n-form-item>
      <!-- <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            专辑
          </p>
          <n-select size="medium" :options="albumOptions" :default-value="1" style="width: 25rem;" />
        </div>
      </n-form-item> -->
      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            BlockChain
          </p>
          <n-select v-model:value="dialogForm.chain" size="medium" :options="chainOptions" style="width: 25rem" />
        </div>
      </n-form-item>
      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            BlockChain Address
          </p>
          <n-input v-model:value="dialogForm.blockchainAddress" size="medium" style="min-width:50rem" />
        </div>
      </n-form-item>

      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            Type
          </p>
          <n-input v-model:value="dialogForm.type" type="text" size="medium" style="min-width:50rem" />
        </div>
      </n-form-item>
      <n-form-item>
        <div>
          <p class="font-bold text-1.25rem mb-0.75rem">
            创作者分成
          </p>
          <n-input v-model:value="dialogForm.creatorDivision" />
        </div>
      </n-form-item>
      <n-form-item>
        <n-button class="w-7.5rem" :loading="isFetching" @click="submit">
          Create
        </n-button>
      </n-form-item>
    </n-form>
  </layouts-wrapper>
</template>

<style scoped>
:deep() .n-upload-trigger.n-upload-trigger--image-card {
  width: 25rem;
  height: 25rem;
}
</style>
