<script setup lang="ts">
import { modalProps } from 'naive-ui'
import { useUserStore } from '@eggor/vue-shared'
import { useAccount } from 'use-wagmi'

const props = defineProps(modalProps)
const { t } = useI18n()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const websiteStore = useWebsiteAppStore()
const invitation = computed(() => route.query.invitation)

const { token } = storeToRefs(userStore)

const { address } = useAccount()

const step = ref<'connectWallet' | 'signIn' | 'logged'>('connectWallet')

watchPostEffect(() => {
  if (!props.show)
    return

  if (!address.value) {
    step.value = 'connectWallet'
    return
  }
  if (address.value && !userStore.id) {
    step.value = 'signIn'
  }
  if (token.value && userStore.id) {
    step.value = 'connectWallet'

    if (route.query.invitation) {
      router.replace({
        ...route,
        query: undefined,
      })
    }
  }
})

onMounted(() => {
  if (invitation.value && (!address.value || !userStore.id)) {
    websiteStore.$patch({ showWalletConnector: true })
  }
})
</script>

<template>
  <n-modal
    class="sing-in-panel"
    v-bind="props"
    preset="card"
    :title="t('core.signInModal.title')"
    size="huge"
    :mask-closable="step !== 'signIn'"
  >
    <sign-in-modal-inviter />
    <n-tabs
      :value="step"
      size="large"
      animated
      :tab-style="{ display: 'none' }"
    >
      <n-tab-pane name="connectWallet" tab="Connect wallet">
        <sign-in-modal-tab-wallet-connector />
      </n-tab-pane>

      <n-tab-pane name="signIn" tab="Sign in">
        <sign-in-modal-tab-sign-in />
      </n-tab-pane>
    </n-tabs>
  </n-modal>
</template>

<style scoped lang="scss"></style>
