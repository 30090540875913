<script setup lang="tsx">
// import type { AgentUserModel } from '@eggor/vue-shared'
// import { WebsiteApi, useUserStore } from '@eggor/vue-shared'
import { type MenuOption, useThemeVars } from 'naive-ui'
import { RouterLink } from 'vue-router/auto'
import type { RouteNamedMap } from 'vue-router/auto/routes'

definePage({
  name: 'MyUserProfile',
  redirect: { name: 'UserCommunityManage' },
})

const { t } = useI18n()
const theme = useThemeVars()
// const userStore = useUserStore()
const route = useRoute<'MyUserProfile'>()
const uid = route.params.id
// const { data: userInfoResult } = WebsiteApi.useAgentInfo((userStore.user?.userId) ?? '')
const gradeStore = useGradeStore()
// const grade = ref<number>(0)
// watch(userInfoResult, () => {
//   if (userInfoResult.value) {
//     const user: AgentUserModel[] = userInfoResult.value.data?.artistList.filter((i: any) => (i.userId).toString() === uid) ?? []
//     if (user.length !== 0)
//       grade.value = user[0].grade
//     else {
//       grade.value = (userInfoResult.value.data?.collectorList.filter((i: any) => (i.userId).toString() === uid)[0] as AgentUserModel).grade
//     }
//   }
// })
 type MenuOptionType = (MenuOption & { key: keyof RouteNamedMap })[]
const menuOptions = computed<MenuOptionType>(() => [/* {
  label: () => (<RouterLink to={{ name: 'AgentProfile', query: { noTransition: 'true' }, params: { id: uid } }}>{t('agent.profile.myUser.back')}</RouterLink>),
  key: 'AgentProfile',
},  */{
    label: () => (<RouterLink to={{ name: 'UserCommunityManage', query: { noTransition: 'true' }, params: { id: uid } }}>{t('profile.communityManage.title')}</RouterLink>),
    key: 'UserCommunityManage',
  },
  {
    label: () => (<RouterLink to={{ name: 'UserEdit', query: { noTransition: 'true' }, params: { id: uid } }}>{t('profile.edit.title')}</RouterLink>),
    key: 'UserEdit',
    show: gradeStore.grade >= 2,
  },
  {
    label: () => (<RouterLink to={{ name: 'UserEventPage', query: { noTransition: 'true' }, params: { id: uid } }}>{t('profile.activityManage.title')}</RouterLink>),
    key: 'UserEventPage',
    show: gradeStore.grade >= 3,
  },
  {
    label: () => (<RouterLink to={{ name: 'UserAlbumPage', query: { noTransition: 'true' }, params: { id: uid } }}>{t('profile.myNFT.title')}</RouterLink>),
    key: 'UserAlbumPage',
    show: gradeStore.grade >= 3,
  },
])
</script>

<template>
  <n-config-provider
    :theme="theme"
    :theme-overrides="{
      Menu: {
        itemHeight: '5rem',
        itemTextColorActive: 'white',
        itemColorActive: '#191715',
        itemColorHover: '#ebebeb',
        itemColorActiveHover: '#191715',
        itemTextColorActiveHover: 'white',
      },
    }"
  >
    <layouts-wrapper class="profile row gap-1.25rem pt-4.375rem">
      <n-menu
        :value="route.name"
        class="w-16.5625rem border-1 border-#BBB7B6 rounded shrink-0 h-min sticky top-0"
        :options="menuOptions"
      />

      <section>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </section>
    </layouts-wrapper>
  </n-config-provider>
</template>

<style scoped>
.profile section {
  flex-grow: 1;
}
:deep(.n-menu) {
  padding-bottom: 0.625rem;
}
:deep(.n-menu .n-menu-item) {
  margin-top: 0.625rem;
}
:deep(.n-menu-item-content) {
  padding-right: 32px;
  text-align: center;
}
:deep(.n-menu .n-menu-item-content::before) {
  left: 0.625rem;
  right: 0.625rem;
}
:deep(.n-menu .n-menu-item-content.n-menu-item-content--selected) {
  overflow: hidden;
}
:deep(.n-menu .n-menu-item-content.n-menu-item-content--selected::after) {
  content: '';
  border: solid 1.3rem white;
  opacity: 0.1;
  position: absolute;
  top: -3rem;
  right: -2rem;
  border-radius: 100%;
  width: 6.5rem;
  height: 6.5rem;
}
</style>
