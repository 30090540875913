<script setup lang='ts'>
import { WebsiteApi } from '@eggor/vue-shared'
import { type FormInst, type FormRules, useThemeVars } from 'naive-ui'

definePage({
  name: 'LegalizeCollector',
})
const { t, tm, rt } = useI18n()
const messager = useMessage()
const router = useRouter()

const { data: existingForm, isFetching: isExistingFormFetching, execute: fetchExistingForm, error } = WebsiteApi.useMyUserIdentity()
onActivated(() => {
  fetchExistingForm()
})
const queryParams = ref({
  applyIdentity: 2,
  name: '',
  tel: '',
  email: '',
  country: '',
  address: '',
  bio: '',
  artworkFiles: [],
  externalLinks: [''],
  ownershipFiles: [],
  personalLinks: [],
  personalPhotos: [],
  status: undefined as 0 | 1 | 2 | 3 | undefined,
})
const { showWalletConnector } = storeToRefs(useWebsiteAppStore())
const theme = useThemeVars()
const formRef = ref<FormInst | null>(null)
const submit = ref(false)
const { data, isFetching, execute } = WebsiteApi.useCreateUserIdentity(queryParams, submit, {})
const rules: FormRules = {
  name: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.name') }),
    },
  ],
  tel: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.tel') }),
    },
  ],
  email: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.basicInfo.email') }),
    },
  ],
  bio: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsInput', { label: t('legalize.collector.introduction') }),
    },
  ],
  externalLinks: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.collector.atLastOneSampleExternalLink'),
      validator: (rule, value: string[]) => value.filter(_ => !!_).length > 0,
    },
  ],
  artworkFiles: [
    {
      required: true,
      trigger: ['blur', 'change'],
      message: t('legalize.plsUpload', { label: t('legalize.collector.sample') }),
      validator: (rule, value: string[]) => value.length > 0,
    },
  ],
}
watch(existingForm, (existingForm) => {
  if (existingForm?.data) {
    if (existingForm.data.applyIdentity === 1 && [1, 3].includes(existingForm.data.status ?? 0)) {
      messager.warning(t('legalize.collector.tag'))
      router.replace({ name: 'LegalizeArtist' })
      queryParams.value = {
        ...queryParams.value,
        ...(existingForm.data as any),
      }
    }
    else if (existingForm.data.applyIdentity === 2) {
      queryParams.value = {
        ...queryParams.value,
        ...(existingForm.data as any),
      }
    }
  }
})
watch(data, (data) => {
  if (data) {
    messager.success(t('legalize.applicationSubmitted'))
    router.replace({ name: 'ProfileAuthentication' })
  }
})
watch(error, (error) => {
  if (error && error.code === 401) {
    showWalletConnector.value = true
  }
})
useOnUserChanged(() => fetchExistingForm(false))
function handleValidateButtonClick(e: MouseEvent, withSubmit: boolean = false) {
  e.preventDefault()
  formRef.value?.validate((errors) => {
    if (errors)
      return null
    submit.value = withSubmit
    execute()
  })
}
const isDisabledForm = computed(() => isFetching.value || [1, 3].includes(queryParams.value.status ?? 0))
provide('isDisabledForm', isDisabledForm)
</script>

<template>
  <layouts-wrapper class="pt-4.375rem">
    <n-layout>
      <n-layout-header
        class="font-bold text-1.625rem text-[#403f3e] lh-1.875rem"
      >
        {{ t('legalize.collector.title') }}
      </n-layout-header>
      <div
        class="mt-5 description lh-1rem"
      >
        {{ t('legalize.collector.notice') }}
      </div>
      <n-layout-content class="max-w-62.5rem w-100%">
        <n-spin :show="isExistingFormFetching">
          <n-form
            ref="formRef"
            :disabled="isFetching || [1, 3].includes(queryParams.status ?? 0)"
            :model="queryParams"
            :rules="rules"
            class="col gap-1.875rem"
          >
            <!-- 基本信息 -->
            <section class="col gap-1.25rem">
              <div
                class="text-1rem font-bold lh-1rem lh-2.5rem mt-3.125rem "
              >
                {{ t('legalize.basicInfo.title') }}
              </div>

              <legalize-fragment-basic-info v-model="queryParams" />
            </section>

            <!-- 介绍 -->
            <section class="col gap-1.25rem">
              <div
                class=" text-1rem font-bold lh-1rem lh-2.5rem"
              >
                {{ t('legalize.collector.introduction') }}
                <span :style="{ color: theme.errorColor }">&nbsp;*</span>
              </div>
              <div>
                <p
                  v-for="line, index in tm('legalize.collector.introductionNotice')"
                  :key="index"
                  class=" font-500 text-[#6E6C6B] lh-1rem"
                >
                  - {{ rt(line as any) }}
                </p>
              </div>
              <n-form-item :label="t('legalize.collector.introduction')" path="bio">
                <n-input
                  v-model:value="queryParams.bio"
                  clearable
                  type="textarea"
                  :autosize="{
                    minRows: 5,
                  }"
                />
              </n-form-item>
            </section>

            <!-- 样品 -->
            <section class="col gap-1.25rem">
              <div
                class=" text-1rem font-bold lh-1rem lh-2.5rem"
              >
                {{ t('legalize.collector.physicalCollection') }}
                <span :style="{ color: theme.errorColor }">&nbsp;*</span>
              </div>

              <div>
                <p
                  v-for="line, index in tm('legalize.collector.physicalCollectionNotice')"
                  :key="index"
                  class=" font-500 text-[#6E6C6B] lh-1rem"
                >
                  - {{ rt(line as any) }}
                </p>
              </div>

              <legalize-fragment-physical-collection v-model="queryParams" />
            </section>

            <!-- 权属证明 -->
            <section class="col gap-1.25rem">
              <div
                class=" text-1rem font-bold lh-1rem lh-2.5rem"
              >
                {{ t('legalize.collector.ownership') }}
              </div>

              <div>
                <p
                  v-for="line, index in tm('legalize.collector.ownershipNotice')"
                  :key="index"
                  class=" font-500 text-[#6E6C6B] lh-1rem"
                >
                  - {{ rt(line as any) }}
                </p>
              </div>

              <legalize-fragment-ownership v-model="queryParams" />
            </section>

            <!-- 社交媒体和网站 -->
            <section class="col gap-1.25rem">
              <div
                class=" text-1rem font-bold lh-1rem lh-2.5rem"
              >
                {{ t('legalize.socialMediaAndWebsite.title') }}
              </div>

              <legalize-fragment-social-media v-model="queryParams" />
            </section>
          </n-form>

          <div class="mt-6.25rem">
            <n-button
              v-if="![1, 3].includes(queryParams.status ?? 0)"
              :loading="isFetching && !submit"
              :disabled="isFetching && submit"
              type="default"
              @click="(e) => handleValidateButtonClick(e)"
            >
              {{ t('save') }}
            </n-button>

            <n-button
              v-if="![1, 3].includes(queryParams.status ?? 0)"
              :loading="isFetching && submit"
              :disabled="isFetching && !submit"
              class="ml-10"
              type="primary"
              @click="(e) => handleValidateButtonClick(e, true)"
            >
              {{ t('legalize.send') }}
            </n-button>
          </div>
        </n-spin>
      </n-layout-content>
    </n-layout>
  </layouts-wrapper>
</template>

<style scoped lang="scss">
:deep(.n-form-item-label__text) {
  font-weight: bold;
}
</style>
