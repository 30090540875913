<script setup lang="ts">
import { WebsiteApi } from '@eggor/vue-shared'
import { EventPostPublished } from '~/constants'

definePage({
  name: 'CommunityHome',
})
const route = useRoute<'CommunityHome'>()
// 带参页面不要响应式！
const id = route.params.id
const communityResult = WebsiteApi.useCommunity(id)

useAdaptiveRefetchByEvent(EventPostPublished, communityResult)
</script>

<template>
  <layouts-wrapper>
    <e-remote class="pt-3.125rem" :result="communityResult">
      <template #default="{ data }">
        <community-home :community="data.value!.data!" />
      </template>
    </e-remote>
  </layouts-wrapper>
</template>

<style scoped>

</style>
