<script setup lang="tsx">
import type { MenuOption } from 'naive-ui'
import { RouterLink } from 'vue-router'

definePage({
  name: 'Follow',
  meta: {
    noContentWidthRestrictions: true,
    noContentPadding: true,
  },
  redirect: { name: 'FollowFans' },
})
const { t } = useI18n()
const routeMap = {
  FollowFans: t('follow.fans.title'),
  FollowCollectors: t('follow.collector.title'),
  FollowArtists: t('follow.artist.title'),
}
const route = useRoute()

const menuOptions: MenuOption[] = [
  {
    label: () => (
      <RouterLink to={{ name: 'FollowFans', query: { noTransition: 'true' } }}>
        {routeMap.FollowFans}
      </RouterLink>
    ),
    key: 'FollowFans',
  },
  {
    type: 'divider',
  },
  {
    label: 'Following',
    key: 'follows',
    children: [
      {
        label: () => (
          <RouterLink to={{ name: 'FollowCollectors', query: { noTransition: 'true' } }}>
            {routeMap.FollowCollectors}
          </RouterLink>
        ),
        key: 'FollowCollectors',
      },
      {
        label: () => (
          <RouterLink to={{ name: 'FollowArtists', query: { noTransition: 'true' } }}>
            {routeMap.FollowArtists}
          </RouterLink>
        ),
        key: 'FollowArtist',
      },
    ],
  },
]
</script>

<template>
  <layouts-wrapper class="follow relative gap-0.94rem">
    <div class="absolute inset-0 " />

    <div class="sider w-23.4375rem">
      <div class="h-6.25rem line-height-6.25rem text-center font-400 text-1rem" style="background-color: rgba(255, 255, 255, 0.75);">
        {{ t('follow.title') }}
      </div>

      <div class="py-2.06rem px-3.44rem">
        <n-menu
          :options="menuOptions"
          :indent="0"
          :default-expand-all="true"
          :value="route.name"
        />
      </div>
    </div>

    <div class="content gap-0.94rem w-full max-w-75rem col items-stretch">
      <div class="shrink-0 py-1.25rem px-4.38rem font-400 text-1rem rounded bg-[rgba(255,255,255,0.85)] shadow-sm">
        {{ routeMap[route.name as 'FollowFans'] }}
      </div>

      <div class="grow min-h-80dvh">
        <router-view />
      </div>
    </div>
  </layouts-wrapper>
</template>

<style scoped lang="scss">
.follow {
  background-image: url("/images/letter_box_background.jpg");
  padding-top: 2rem;
  padding-bottom: 4.25rem;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;

  .sider {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    flex-shrink: 0;
    :deep() {
      .n-list-item__main, .n-menu-item-content-header{
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .n-menu-item-content--selected {
        a {
          color: #FF8D0D;
        }
        &:hover {
          a {
            color: #FF8D0D !important;
          }
        }
      }
      .n-menu .n-menu-item-content::before {
        left: 0;
        right: 0;
      }
      .n-menu-item-content {
        padding: 12px 20px !important;
      }
      .n-menu .n-menu-item-content.n-menu-item-content--selected::before {
        background-color: transparent;
      }
      .n-menu-divider {
        background-color: hsla(30, 6%, 87%, 1) !important;
        margin: 0;
      }
    }
  }

  .content {
    padding: 1.81rem 1.87rem;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
  }
}
</style>
