<script setup lang="ts">
import { AdminApi, WebsiteApi, useUserStore } from '@eggor/vue-shared'
import type { FormInst, FormItemRule, UploadFileInfo } from 'naive-ui'
import type { CommunityIdentityModel, UploadFileResponse } from '@eggor/vue-shared'
import type { FileInfo, OnBeforeUpload, OnFinish, OnRemove, UploadInst } from 'naive-ui/es/upload/src/interface'
import router from '~/router'

definePage({
  name: 'CommunityCreation',
})
const uploadRef = ref<UploadInst>()
const messager = useMessage()
const userStore = useUserStore()
const headers = computed(() => ({
  Authorization: `Bearer ${userStore.token}`,
}))
const { t } = useI18n()
const formRef = ref<FormInst>()
const selectedCategories = ref()
const queryParams = ref<Partial<CommunityIdentityModel >>({
  communityName: '',
  bio: '',
  photo: undefined,
  productionUrl: [],

})
const userInfoResult = AdminApi.useInfo()
const { data, isFetching, execute } = WebsiteApi.useCreateCommunity(computed(() => ({
  ...queryParams.value,
  categories: selectedCategories.value,
})))

const fileList = ref<FileInfo[]>([])
const worksList = ref<FileInfo[]>([])
const disableButton = ref(false)
watch(fileList, (fileList) => {
  if (fileList.length > 0)
    queryParams!.value.photo = fileList.map(file => file.url)[0] as string
})
watch(worksList, (worksList) => {
  queryParams.value.productionUrl = worksList.map(work => work.url) as string[]
})
watch(data, (data) => {
  if (data) {
    messager.success(t('community.creation.successfully'))
    // 创建成功后重置数据
    queryParams.value = {
      communityName: '',
      bio: '',
      photo: undefined,
      productionUrl: [],

    }
    selectedCategories.value = ''
    fileList.value = []
    worksList.value = []
    uploadRef.value?.clear()
    router.replace({ name: 'ProfileCommunityManage' })
  }
})
onUserChanged(() => userInfoResult.execute())

const handleUploadFinish: OnFinish = ({ event, file }) => {
  try {
    const response = JSON.parse((event?.target as XMLHttpRequest).responseText) as UploadFileResponse
    file.url = response.url
    return file
  }
  catch (err) {
    console.error(err)
  }
  finally {
    disableButton.value = false
  }
}
const handleRemove: OnRemove = ({ fileList: list }) => {
  fileList.value = list
  queryParams.value.photo = undefined
}

const checkFile: OnBeforeUpload = ({ file }) => {
  if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
    messager.error(t('community.creation.illegalImage'))
    return false
  }
  if (file.file!.size > 5 * 1024 * 1024) {
    messager.error(t('community.creation.illegalImageSize'))
    return false
  }
  disableButton.value = true
  return true
}

const rules = {
  communityName: [
    {
      required: true,
      message() { return t('community.creation.noName') },
    },
    {
      validator(rule: FormItemRule, value: string) {
        return value.trim().length < 64
      },
      message() {
        return t('community.creation.nameTooLang', { length: queryParams.value.communityName?.trim().length })
      },
    },
  ],
  bio: [
    {
      required: true,
      message() { return t('community.creation.noBio') },
    },
    {
      validator(rule: FormItemRule, value: string) {
        return value.trim().length < 1000
      },
      message() {
        return t('community.creation.bioTooLang', { length: queryParams.value.bio?.trim().length })
      },
    },

  ],
  // category: [{ required: true }],

} as Record<string, FormItemRule>

const showModalRef = ref(false)
const previewImageUrlRef = ref('')
const previewList: string[] = []
function handlePreview(file: UploadFileInfo) {
  const objectUrl = URL.createObjectURL(file.file!)
  previewList.push(objectUrl)
  previewImageUrlRef.value = objectUrl
  showModalRef.value = true
}
onUnmounted(() => {
  for (const objectUrl of previewList) {
    URL.revokeObjectURL(objectUrl)
  }
})

const onRemoveWorks: OnRemove = ({ fileList: list }) => {
  worksList.value = list
}

function handleSubmit() {
  formRef.value?.validate(async (errors) => {
    if (errors)
      return null
    if (!queryParams.value.photo) {
      return messager.error(t('community.creation.noImage'))
    }

    // const applied = await alreadyApplied()
    // if (applied) {
    //   return messager.error(t('community.creation.alreadyApplied'))
    // }
    // if (await alreadyHave()) {
    //   return messager.error(t('community.creation.alreadyHave'))
    // }
    execute()
  })
}

// async function alreadyApplied() {
//   const { data } = await WebsiteApi.useMyCommunityIdentity()
//   if (data.value?.data && data.value?.data?.status !== 3) {
//     return true
//   }
//   return false
// }
// async function alreadyHave() {
//   const { data } = await WebsiteApi.useCommunityByUserId(computed(() => userStore.user?.userId))
//   if (data.value?.data) {
//     return true
//   }
//   return false
// }
</script>

<template>
  <layouts-wrapper>
    <n-layout>
      <n-layout-header class="relative w-full">
        <img loading="lazy" src="/images/community_creation.png" class="w-full" alt="">
        <div class="mask" />
        <h2
          class="absolute right-8.125rem top-50% translate-y--50% text-1.875rem font-800 text-[#FFFFFF] lh-2.25rem"
        >
          {{ t('community.creation.title') }}
        </h2>
      </n-layout-header>

      <e-remote :result="userInfoResult">
        <template #default="{ data }">
          <n-layout has-sider class="mt-3.125rem">
            <n-layout-sider width="auto" class="text-center">
              <n-card class="w-15.25rem min-h-18.875rem">
                <n-avatar
                  v-if="fileList.length > 0 && fileList[0].file"
                  :src="(fileList[0].url!)"
                  class="w-11rem h-11rem m-auto object-cover object-center"
                  circle
                />
                <div v-else class="w-11rem h-11rem lh-11rem text-center m-auto mt-1.5625rem">
                  <!-- <span class="i-carbon-add-alt text-6rem text-gray" /> -->
                  <div class="border-0.25rem w-8.375rem h-8.375rem border-dashed rounded-100% border-[#403F3E] m-auto flex items-center justify-center">
                    <span class="i-icon-park-outline-plus text-3rem text-[rgba(51, 51, 51, 1)]" />
                  </div>
                </div>
                <n-upload
                  v-model:file-list="fileList"
                  action="/admin-api/eggor/uploadFile/uploadMinio"
                  :headers="headers"
                  :on-error="() => { messager.error(t('fileUploadFailed')) }"
                  :max="1"
                  accept=".jpg, .jpeg, .png"
                  @finish="handleUploadFinish"
                  @remove="handleRemove"
                  @before-upload="checkFile"
                >
                  <n-button class="mt-3">
                    {{ t('community.creation.uploadAvatar') }}
                  </n-button>
                </n-upload>
              </n-card>
            </n-layout-sider>

            <n-layout-content class="ml-3.75rem">
              <n-form ref="formRef" :rules="rules" :model="queryParams">
                <n-form-item :show-require-mark="false" :label="t('community.creation.role')">
                  <n-button
                    class="text-1rem font-500 text-[#191715] lh-1.1875rem"
                  >
                    {{ parseMultilingual(data.value?.user.dept.deptName) }}
                  </n-button>
                </n-form-item>
                <n-form-item :show-require-mark="false" :label="t('community.creation.name')" path="communityName">
                  <n-input
                    v-model:value="queryParams.communityName"
                    :placeholder="t('community.creation.namePlaceholder')"
                    clearable
                  />
                </n-form-item>
                <n-form-item :show-require-mark="false" :label="t('community.creation.introduction')" path="bio">
                  <n-input
                    v-model:value="queryParams.bio"
                    :placeholder="t('community.creation.bioPlaceholder')"
                    type="textarea"
                    autosize
                    class="min-h-12rem"
                  />
                </n-form-item>
                <n-form-item :show-require-mark="false" :label="t('community.creation.category')" path="category">
                  <community-creation-category-select
                    v-model="selectedCategories"
                  />
                </n-form-item>

                <n-form-item :label="t('community.creation.yourWorks')">
                  <n-upload
                    v-model:file-list="worksList"
                    action="/admin-api/eggor/uploadFile/uploadMinio"
                    list-type="image-card"
                    :headers="headers"
                    accept=".jpg, .jpeg, .png"
                    @finish="handleUploadFinish"
                    @remove="onRemoveWorks"
                    @preview="handlePreview"
                    @before-upload="checkFile"
                  />
                  <n-modal
                    v-model:show="showModalRef"
                    preset="card"
                    style="width: 37.5rem"
                  >
                    <img :src="previewImageUrlRef" style="width: 100%">
                  </n-modal>
                </n-form-item>
              </n-form>
              <n-button
                :loading="isFetching"
                type="primary"
                color="black"
                :disabled="disableButton"
                @click="handleSubmit"
              >
                {{ t('submit') }}
              </n-button>
            </n-layout-content>
          </n-layout>
        </template>
      </e-remote>
    </n-layout>
  </layouts-wrapper>
</template>

<style scoped>
.mask {
  position: absolute;
  inset: 0;
  right: 50%;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(25,23,21,1) 80%, rgba(25,23,21,1) 60%);
}
:deep() .n-upload-trigger.n-upload-trigger--image-card {
  width: 10.9375rem;
  height: 10.9375rem;
}
:deep() .n-upload-file-list .n-upload-file.n-upload-file--image-card-type {
  width: 10.9375rem;
  height: 10.9375rem;
}
:deep() .n-upload-file-list.n-upload-file-list--grid {
  grid-template-columns: repeat(auto-fill, 10.9375rem);
}
</style>
