<script setup lang="ts">
definePage({
  name: 'UserSpaceOffers',
  meta: {
    ignoreScrollToTop: 1,
  },
})
</script>

<template>
  <!-- <user-space-offers /> -->
  <div class="mt-1.875rem">
    <n-empty />
  </div>
</template>

<style scoped>

</style>
